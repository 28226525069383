import { SnapshotFloorPlan } from '@components/floorPlan/SnapshotFloorPlan';
import { ContactInfo } from 'restaurantAdmin/components/contactInfo/ContactInfo';
import { GuestNotes } from 'restaurantAdmin/components/guestNotes/GuestNotes';
import { GuestTags } from 'restaurantAdmin/components/guestTags/GuestTags';
import { NotesToRestaurant } from 'restaurantAdmin/components/notesToRestaurant/NotesToRestaurant';
import { ReservationGuestSheetStatuses } from 'restaurantAdmin/components/reservationGuestSheetStatuses/ReservationGuestSheetStatuses';
import { ReservationOptions } from 'restaurantAdmin/components/reservationOptions/ReservationOptions';
import { AssignedServer } from 'restaurantAdmin/components/server/AssignedServer';
import {
  OccupantType,
  type ReservationsApiGuest,
  type ServiceReservation,
} from 'restaurantAdmin/reservations/service/apiHelpers';
import { useReservationServiceContext } from 'restaurantAdmin/reservations/service/state/ReservationServiceContext';
import { useServersContext } from '../servers/ServersContext';
import styles from './ReservationGuestSheet.scss';
import { ReservationGuestSheetActions } from './ReservationGuestSheetActions';
import { ReservationGuestSheetHeader } from './ReservationGuestSheetHeader';
import { ReservationGuestSheetReceipt } from './ReservationGuestSheetReceipt';

export interface ReservationGuestSheetProps {
  guest: ReservationsApiGuest;
  onEnableSeatMode: () => void;
  reservation: ServiceReservation;
  seatModeEnabled: boolean;
}

export const ReservationGuestSheet = ({
  guest,
  onEnableSeatMode,
  reservation,
  seatModeEnabled,
}: ReservationGuestSheetProps) => {
  const { id: guestId, phone, email } = guest;
  const {
    id: reservationId,
    transactionDetails: { fee, price, tax, receiptNumber, refundAmount },
    seatedTableName,
    server,
  } = reservation;

  const isHostBooked = !!reservation.transactionDetails.createdBy.fullName;
  const isSeated = Boolean(seatedTableName);

  const { refreshOccupants } = useReservationServiceContext();
  const { hasServers } = useServersContext();

  return (
    <div className={styles.container}>
      <ReservationGuestSheetHeader guest={guest} reservation={reservation} />
      <div className={styles.actions}>
        {!seatModeEnabled && (
          <ReservationGuestSheetActions
            isSeated={isSeated}
            onEnableSeatMode={onEnableSeatMode}
            reservationId={reservationId}
          />
        )}
        <ReservationOptions reservation={reservation} />
      </div>
      {hasServers && isSeated && (
        <AssignedServer
          occupant={{
            occupantId: reservation.id,
            occupantType: OccupantType.Reservation,
          }}
          server={server}
        />
      )}
      <ReservationGuestSheetStatuses
        isHostBooked={isHostBooked}
        isNoShow={false}
        isStranded={reservation.isStranded}
        isCanceled={false}
      />
      <div className={styles.floorplan}>
        <SnapshotFloorPlan reservationId={reservationId} disableControls />
      </div>
      <ContactInfo phone={phone} email={email} />
      <GuestTags guestId={guestId} onUpdate={refreshOccupants} />
      <GuestNotes guestId={guestId} onUpdate={refreshOccupants} />
      <NotesToRestaurant note={reservation.note} />
      <ReservationGuestSheetReceipt
        fee={fee}
        price={price}
        receiptNumber={receiptNumber}
        tax={tax}
        refundAmount={refundAmount}
      />
    </div>
  );
};
