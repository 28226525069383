import { offset } from '@floating-ui/react';
import { Option } from '@mui/base/Option';
import {
  Select as MUISelect,
  type SelectProps as MUISelectProps,
  type SelectListboxSlotProps,
  type SelectRootSlotProps,
} from '@mui/base/Select';
import cx from 'classnames';
import { type ForwardedRef, forwardRef } from 'react';
import { FormListbox } from '@components/formListbox/FormListbox';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './Select.scss';

interface SelectProps extends MUISelectProps<string, false> {
  options: { label: string; value: string }[];
}

export const Select = (props: SelectProps) => {
  const { options, className, ...rest } = props;

  return (
    <div className={cx(styles.container, className)}>
      <MUISelect
        slots={{
          root: SelectButton,
          listbox: SelectListbox,
        }}
        slotProps={{
          popup: {
            // open: true,
            disablePortal: true,
            middleware: [offset(8)],
            style: {
              zIndex: 10,
              width: '100%',
              boxShadow: '0 16px 16px 0 #272f3e66',
            },
          },
        }}
        className={cx(styles.select, typography.h6)}
        {...rest}
      >
        {options.map((option) => (
          <Option key={option.value} value={option.value}>
            {option.label}
          </Option>
        ))}
      </MUISelect>
    </div>
  );
};

export const SelectButton = forwardRef(
  <TValue extends object, Multiple extends boolean>(
    props: SelectRootSlotProps<TValue, Multiple>,
    ref: ForwardedRef<HTMLButtonElement>,
  ) => {
    const { children, ownerState, ...rest } = props;
    return (
      <button type="button" {...rest} ref={ref}>
        {children}
        <Icon name="chevron" />
      </button>
    );
  },
);
SelectButton.displayName = 'SelectButton';

// wraps FormListBox and avoids forwarding MUI-Select-specific props
export const SelectListbox = forwardRef(
  <OptionValue extends object, Multiple extends boolean>(
    props: SelectListboxSlotProps<OptionValue, Multiple>,
    ref: ForwardedRef<HTMLUListElement>,
  ) => {
    const { ownerState, ...attrs } = props;
    return <FormListbox {...attrs} ref={ref} />;
  },
);
SelectListbox.displayName = 'SelectListbox';
