import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import typography from '~styles/typography.scss';
import styles from './NotesToRestaurant.scss';

export const NotesToRestaurant = ({ note }: { note: string | null }) => {
  if (!note) {
    return null;
  }

  return (
    <section className={styles.container}>
      <header>
        <Icon className={styles.messageCircle} name="messageCircle" />
        <h3 className={typography.h7}>Notes to Restaurant</h3>
      </header>
      <p className={cx(typography.c3_20, styles.noteText)}>{note}</p>
    </section>
  );
};
