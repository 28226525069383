import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import {
  clearSelectedAvailability,
  clearSelectedTime,
  createAvailabilityReducer,
  initialAvailabilityState,
  setAvailabilities,
  setSelectedAvailability,
  setSelectedDate,
  setSelectedFloorPlanListingIds,
  setSelectedGuestCount,
  setSelectedTime,
} from '@shared/availability/availabilityReducer';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { useError } from '@shared/hooks/useError';
import type { FloorPlanData } from '@shared/types/floorPlans';
import { todayInTimezone, toISODateFormat } from '@shared/utils/dateFormatters';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { isRestaurantClosed } from 'restaurantAdmin/reservations/apiHelpers';
import { useAdminFloorPlans } from '../../hooks/useAdminFloorPlans';
import type { AdminAvailabilityData } from './apiHelpers';
import { getAvailabilities } from './apiHelpers';
import {
  calculateFilteredData,
  getAvailabilitiesGroupedByTime,
} from './conciergeAvailabilityDerivedState';

export interface ConciergeAvailabilityContextState {
  clearSelectedAvailability: () => void;
  clearSelectedTime: () => void;
  expandedAvailabilityListingIds: string[];
  filteredAvailabilities: AdminAvailabilityData[];
  filteredAvailabilitiesGroupedByTime: Record<string, AdminAvailabilityData[]>;
  hasAvailabilitiesOnGivenDay: boolean;
  hasUnsupportedGuestCount: boolean;
  isClosedToday: boolean;
  isLoading: boolean;
  isSelectedDateToday: boolean;
  selectedAvailability: AdminAvailabilityData | null;
  selectedDate: Date;
  selectedFloorPlanListingIds: string[];
  selectedGuestCount: number;
  selectedTime: string | null;
  setSelectedAvailability: (availability: AdminAvailabilityData) => void;
  setSelectedDate: (date: Date) => void;
  setSelectedFloorPlanListingIds: (ids: string[]) => void;
  setSelectedGuestCount: (guestCount: number) => void;
  setSelectedTime: (time: string) => void;
  selectedFloorPlan: FloorPlanData;
  setSelectedFloorPlanId: Dispatch<SetStateAction<string>>;
  floorPlans: FloorPlanData[];
}

const ConciergeAvailabilityContext =
  createContext<ConciergeAvailabilityContextState | null>(null);
ConciergeAvailabilityContext.displayName = 'ConciergeAvailabilityContext';

export const useConciergeAvailabilityContext = () =>
  useDefinedContext(ConciergeAvailabilityContext);

interface ConciergeAvailabilityContextProviderProps {
  children: ReactNode;
}

export const ConciergeAvailabilityContextProvider = ({
  children,
}: ConciergeAvailabilityContextProviderProps) => {
  const restaurant = useRestaurant();
  const [isClosedToday, setIsClosedToday] = useState(true);
  const conciergeAvailabilityReducer = createAvailabilityReducer<
    AdminAvailabilityData,
    AdminAvailabilityData
  >();
  const [state, dispatch] = useReducer(
    conciergeAvailabilityReducer,
    initialAvailabilityState<AdminAvailabilityData, AdminAvailabilityData>(
      restaurant.timezone,
    ),
  );
  const {
    availabilities,
    selectedGuestCount,
    selectedTime,
    selectedDate,
    selectedFloorPlanListingIds,
    selectedAvailability,
  } = state;

  const setError = useError();

  useEffect(() => {
    if (restaurant.id) {
      // setting initial date to today in restaurant's timezone
      setSelectedDate(new Date(todayInTimezone(restaurant.timezone)));
      const checkRestaurantIsClosed = async () => {
        try {
          const isRestaurantClosedResponse = await isRestaurantClosed(
            restaurant.id,
            todayInTimezone(restaurant.timezone),
          );

          setIsClosedToday(isRestaurantClosedResponse);
        } catch (e) {
          setError(e);
        }
      };

      void checkRestaurantIsClosed();
    }
  }, [restaurant.id]);

  const { isPending: isAvailabilitiesLoading } = useAbortEffect(async () => {
    if (restaurant.id) {
      // clear availabilities when fetch is in progress
      // prevent user from choosing an availability while fetching
      dispatch(setAvailabilities([]));
      const allAvailabilities = await getAvailabilities(
        restaurant.id,
        selectedDate,
      );
      void dispatch(setAvailabilities(allAvailabilities));
    }
  }, [selectedDate, restaurant]);

  const {
    floorPlans,
    isLoading: isFloorPlanLoading,
    setSelectedFloorPlanId,
    selectedFloorPlan,
  } = useAdminFloorPlans(true);

  const {
    filteredAvailabilities,
    expandedAvailabilityListingIds,
    hasUnsupportedGuestCount,
  } = calculateFilteredData(state);

  const filteredAvailabilitiesGroupedByTime =
    getAvailabilitiesGroupedByTime(state);

  const isSelectedDateToday =
    todayInTimezone(restaurant.timezone) === toISODateFormat(selectedDate);

  const value = useMemo(
    (): ConciergeAvailabilityContextState => ({
      clearSelectedAvailability: () => dispatch(clearSelectedAvailability()),
      clearSelectedTime: () => dispatch(clearSelectedTime()),
      expandedAvailabilityListingIds,
      filteredAvailabilities,
      filteredAvailabilitiesGroupedByTime,
      floorPlans,
      hasAvailabilitiesOnGivenDay: !!availabilities.length,
      hasUnsupportedGuestCount,
      isClosedToday,
      isLoading: isAvailabilitiesLoading || isFloorPlanLoading,
      isSelectedDateToday,
      selectedAvailability,
      selectedDate,
      selectedFloorPlan,
      selectedGuestCount,
      selectedFloorPlanListingIds,
      selectedTime,
      setSelectedAvailability: (availability: AdminAvailabilityData) =>
        dispatch(setSelectedAvailability(availability)),
      setSelectedDate: (date: Date) => dispatch(setSelectedDate(date)),
      setSelectedFloorPlanId,
      setSelectedFloorPlanListingIds: (floorPlanListingIds: string[]) =>
        dispatch(setSelectedFloorPlanListingIds(floorPlanListingIds)),
      setSelectedGuestCount: (guestCount: number) =>
        dispatch(setSelectedGuestCount(guestCount)),
      setSelectedTime: (time: string) => dispatch(setSelectedTime(time)),
    }),
    [
      isClosedToday,
      isAvailabilitiesLoading,
      isFloorPlanLoading,
      state,
      floorPlans,
      selectedFloorPlan,
    ],
  );

  return (
    <ConciergeAvailabilityContext.Provider value={value}>
      {children}
    </ConciergeAvailabilityContext.Provider>
  );
};
