import { TZDate } from '@date-fns/tz';
import cx from 'classnames';
import { secondsToMilliseconds } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ExternalRefundLink } from '@components/externalRefundLink/ExternalRefundLink';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { centsToDollar } from '@shared/utils/currency';
import { toShortDateTime } from '@shared/utils/dateFormatters';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import typography from '~styles/typography.scss';
import {
  cancel,
  collectCancellationFee,
  waiveCancellationFee,
} from '../../reservations/apiHelpers';
import styles from './CancelConfirmationModal.scss';

const CURRENT_PAGE = 0;

const CANCEL_SUCCESS_MESSAGE =
  "Success: The guest's reservation has been successfully canceled.";
const COLLECT_SUCCESS_MESSAGE =
  "Success: The guest's reservation cancellation fee has been collected.";
const WAIVE_SUCCESS_MESSAGE =
  "Success: The guest's reservation cancellation fee has been waived.";

export interface CancelConfirmationModalProps {
  cancellationPolicy: {
    fee: number;
  } | null;
  closeModal: () => void;
  disablePortal?: boolean;
  eligibleForCancellationFeeUntil: string;
  isEligibleForCancellationFeeUponCancellation: boolean;
  isOpen: boolean;
  reservationId: string;
}

export const CancelConfirmationModal = ({
  cancellationPolicy,
  closeModal,
  disablePortal,
  eligibleForCancellationFeeUntil,
  isEligibleForCancellationFeeUponCancellation,
  isOpen,
  reservationId,
}: CancelConfirmationModalProps) => {
  const restaurant = useRestaurant();
  const navigate = useNavigate();
  const [isPending, setIsPending] = useState(false);

  const refreshPage = () =>
    setTimeout(() => navigate(CURRENT_PAGE), secondsToMilliseconds(3));

  const handleCancel = () => {
    setIsPending(true);

    void (async () => {
      try {
        await cancel(restaurant.id, reservationId);

        successToast({ message: CANCEL_SUCCESS_MESSAGE });
      } catch (error) {
        if (error instanceof ApiError) {
          errorToast({ message: error.message });
        } else {
          errorToast({
            message: 'Failed to cancel reservation. Please try again.',
          });
        }
        reportAppError(error);
      }
    })();
    refreshPage();
  };

  const handleCollectFee = () => {
    setIsPending(true);

    void (async () => {
      try {
        await cancel(restaurant.id, reservationId);
        successToast({ message: CANCEL_SUCCESS_MESSAGE });

        await collectCancellationFee(restaurant.id, reservationId);
        successToast({ message: COLLECT_SUCCESS_MESSAGE });
      } catch (error) {
        if (error instanceof Error) {
          errorToast({ message: error.message });
        } else {
          errorToast({
            message: 'Failed to cancel reservation. Please try again.',
          });
        }
        reportAppError(error);
      }
    })();
    refreshPage();
  };

  const handleWaiveFee = () => {
    setIsPending(true);

    void (async () => {
      try {
        await cancel(restaurant.id, reservationId);
        successToast({ message: CANCEL_SUCCESS_MESSAGE });

        await waiveCancellationFee(restaurant.id, reservationId);
        successToast({ message: WAIVE_SUCCESS_MESSAGE });
      } catch (error) {
        if (error instanceof Error) {
          errorToast({ message: error.message });
        } else {
          errorToast({
            message: 'Failed to cancel reservation. Please try again.',
          });
        }
        reportAppError(error);
      }
    })();
    refreshPage();
  };

  const onlyCancelCopy = () => (
    <>
      <h1 className={typography.d3}>Cancel Reservation</h1>
      <p className={cx(typography.c2, styles.message)}>
        Click confirm to cancel this reservation.
      </p>
      <p className={cx(typography.c2, styles.message)}>
        Please reference the Peak &nbsp;
        <ExternalRefundLink className={styles.link} />
        &nbsp; for more detail regarding cancellations.
      </p>
    </>
  );

  const cancelAndCollectCopy = () => (
    <>
      <h1 className={typography.d3}>Cancel Reservation</h1>
      <p className={cx(typography.c2, styles.message)}>
        Select an option below to cancel this reservation.
      </p>
      <p className={cx(typography.c2, styles.message)}>
        You have until{' '}
        {toShortDateTime(
          TZDate.tz(restaurant.timezone, eligibleForCancellationFeeUntil),
        )}{' '}
        to collect the {centsToDollar(cancellationPolicy?.fee)} cancellation fee
        on the Occupants page.
      </p>
      <p className={cx(typography.c2, styles.message)}>
        Please reference the Peak <ExternalRefundLink className={styles.link} />{' '}
        for more details regarding cancellations.
      </p>
    </>
  );

  return (
    <Modal
      ariaLabel={
        isEligibleForCancellationFeeUponCancellation
          ? 'Cancel and Collect Confirmation'
          : 'Cancel Confirmation'
      }
      disablePortal={disablePortal}
      isOpen={isOpen}
      onClose={closeModal}
    >
      {isEligibleForCancellationFeeUponCancellation
        ? cancelAndCollectCopy()
        : onlyCancelCopy()}
      <ModalActions stack={isEligibleForCancellationFeeUponCancellation}>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          isDisabled={isPending}
          label={
            isEligibleForCancellationFeeUponCancellation
              ? 'Confirm Cancel'
              : 'Confirm'
          }
          onClick={handleCancel}
          variant={ButtonVariants.Primary}
        />
        {isEligibleForCancellationFeeUponCancellation && (
          <Button
            isDisabled={isPending}
            label="Cancel & Collect Fee"
            onClick={handleCollectFee}
            variant={ButtonVariants.Secondary}
          />
        )}
        {isEligibleForCancellationFeeUponCancellation && (
          <Button
            isDisabled={isPending}
            label="Cancel & Waive Fee"
            onClick={handleWaiveFee}
            variant={ButtonVariants.Secondary}
          />
        )}
      </ModalActions>
    </Modal>
  );
};
