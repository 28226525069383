import type { IconNameType } from '@shared/types/floorPlans';
import { api } from '../../api';

export interface CreatePricePointPayload {
  activeDays: string[];
  endTime: string;
  price: number;
  startTime: string;
}

export interface EditPricePointPayload {
  activeDays: string[];
  endTime: string;
  id: string;
  price: number;
  startTime: string;
}

export interface DeletePricePointPayload {
  id: string;
}

export interface EditPricePointsPayload {
  added: CreatePricePointPayload[];
  edited: EditPricePointPayload[];
  removed: DeletePricePointPayload[];
}

export interface CreateListingPayload {
  floorPlanId: string;
  highlightedFloorPlanTableIds: string[];
  iconName: IconNameType;
  ignoreWarnings?: boolean;
  interval: number;
  inventoryCount: number;
  isCommunal: boolean;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  price: number;
  pricePoints?: CreatePricePointPayload[];
  publicName: string;
  turnTime: number;
  serviceWindowIds: string[];
}

export interface EditListingPayload {
  floorPlanId: string;
  highlightedFloorPlanTableIds: string[];
  iconName: IconNameType;
  ignoreWarnings: boolean;
  interval: number;
  inventoryCount: number;
  isCommunal: boolean;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  price: number;
  pricePoints?: EditPricePointsPayload;
  publicName: string;
  turnTime: number;
  serviceWindowIds: string[];
}

export interface ListingWarnings {
  warnings: string[];
}

export interface NewListing {
  createdListingId: string;
}

export interface PricePoint {
  activeDays: string[];
  endTime: string;
  id: string;
  price: number;
  startTime: string;
}

export interface ServiceWindow {
  id: string;
  name: string;
  startDate: string;
  endDate: string | null;
  repeat: string[];
  startTime: string;
  endTime: string;
}
export interface ListingWithServiceWindows {
  floorPlanId: string;
  highlightedFloorPlanTableIds: string[];
  iconName: IconNameType;
  id: string;
  interval: number;
  inventoryCount: number;
  isCommunal: boolean;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  price: number;
  publicName: string;
  restaurantId: string;
  status: 'active' | 'draft';
  turnTime: number;
  pricePoints: PricePoint[];
  serviceWindows: ServiceWindow[];
}

interface ListingHasConflicts {
  hasConflicts: boolean;
}

export interface ListingWithPricePoints {
  id: string;
  name: string;
  status: string;
  pricePoints: PricePoint[];
}

export const createListing = (
  restaurantId: string,
  listing: CreateListingPayload,
  isDraft?: boolean,
): Promise<ListingWarnings | NewListing> =>
  api.post(
    `/restaurants/${restaurantId}/listings${isDraft ? '?draft' : ''}`,
    listing,
  );

export const deleteListing = (
  restaurantId: string,
  listingId: string,
): Promise<void> =>
  api.delete(`/restaurants/${restaurantId}/listings/${listingId}`);

export const editListing = (
  restaurantId: string,
  listingId: string,
  listing: EditListingPayload,
): Promise<ListingWarnings> =>
  api.put(`/restaurants/${restaurantId}/listings/${listingId}`, listing);

export const getListing = async (
  listingId: string,
  restaurantId: string,
  signal?: AbortSignal,
): Promise<ListingWithServiceWindows> =>
  api.get<ListingWithServiceWindows>(
    `/restaurants/${restaurantId}/listings/${listingId}`,
    { signal },
  );

export const getListings = async (
  restaurantId: string,
  isDraft?: boolean,
  signal?: AbortSignal,
): Promise<ListingWithServiceWindows[]> =>
  api.get<ListingWithServiceWindows[]>(
    `/restaurants/${restaurantId}/listings${isDraft ? '?draft' : ''}`,
    {
      signal,
    },
  );

export const unpublishListing = (
  restaurantId: string,
  listingId: string,
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/listings/${listingId}/unpublish`);

export const publishListing = (
  restaurantId: string,
  listingId: string,
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/listings/${listingId}/publish`);

export const checkListingHasConflicts = (
  restaurantId: string,
  listingId: string,
  signal?: AbortSignal,
): Promise<ListingHasConflicts> =>
  api.get(`/restaurants/${restaurantId}/listings/${listingId}/has-conflicts`, {
    signal,
  });

export const getServiceWindows = (
  restaurantId: string,
  signal?: AbortSignal,
): Promise<ServiceWindow[]> =>
  api.get<ServiceWindow[]>(`/restaurants/${restaurantId}/service-windows`, {
    signal,
  });

export const getPricePointsByListing = (
  restaurantId: string,
  signal?: AbortSignal,
): Promise<ListingWithPricePoints[]> =>
  api.get<ListingWithPricePoints[]>(
    `/restaurants/${restaurantId}/price-points-by-listing`,
    {
      signal,
    },
  );
