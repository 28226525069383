import { Tab } from '@mui/base/Tab';
import { TabPanel } from '@mui/base/TabPanel';
import { Tabs } from '@mui/base/Tabs';
import cx from 'classnames';
import { useEffect, useState } from 'react';
import { TabsList } from '@components/tabs/TabsList';
import typography from '~styles/typography.scss';
import {
  type BuyableAvailability,
  getLowestPurchasePrice,
} from './availabilityUtil';
import { AvailableTimeButton } from './AvailableTimeButton';
import styles from './AvailableTimes.scss';

interface AvailableTimesProps {
  filteredAvailabilitiesGroupedByTime: Record<string, BuyableAvailability[]>;
  serviceWindows: string[];
  availableTimesGroupedByServiceWindow: [string, string[]][];
  onTimeSelect: (time: string) => void;
}

export const AvailableTimes = ({
  filteredAvailabilitiesGroupedByTime,
  serviceWindows,
  availableTimesGroupedByServiceWindow,
  onTimeSelect,
}: AvailableTimesProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    setTabIndex(0);
  }, [serviceWindows]);

  const renderAvailableTimes = (times: string[]) =>
    times.map((time) => (
      <li key={time}>
        <AvailableTimeButton
          purchasePrice={getLowestPurchasePrice(
            filteredAvailabilitiesGroupedByTime[time],
          )}
          time={time}
          onClick={() => {
            onTimeSelect(time);
          }}
        />
      </li>
    ));

  return (
    <div className={styles.container} data-testid="available-times">
      <Tabs
        value={tabIndex}
        onChange={(_event, newIndex) => {
          setTabIndex(Number(newIndex));
        }}
      >
        <TabsList>
          {serviceWindows.map((serviceWindow, index) => (
            <Tab
              className={cx(typography.t3, styles.serviceWindowTab)}
              key={serviceWindow}
              value={index}
            >
              {serviceWindow}
            </Tab>
          ))}
        </TabsList>
        {availableTimesGroupedByServiceWindow.map(
          ([serviceWindow, times], index) => (
            <TabPanel key={serviceWindow} value={index}>
              <ol aria-labelledby={serviceWindow}>
                {renderAvailableTimes(times)}
              </ol>
            </TabPanel>
          ),
        )}
      </Tabs>
    </div>
  );
};
