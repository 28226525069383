export const HUMAN_READABLE_ICON_NAMES = {
  armChair: 'Arm chair',
  barSeat: 'Bar seat',
  barSeatSmall: 'Bar seat',
  cabanaTable: 'Cabana with table',
  loveSeat: '1 love seat bench',
  mergedTable: 'Merged table',
  ovlTable2Armchairs: 'Oval table with 2 armchairs',
  ovlTable2Benches: 'Oval table with 2 benches',
  ovlTable2LoveSeats: 'Oval table with 2 love seats',
  ovlTable4Armchairs: 'Oval table with 4 armchairs',
  ovlTable6RndChairs: 'Oval table with 6 round chairs',
  recTable10SqrChairs: 'Rectangular table with 10 square chairs',
  recTable1Bench1SqrChairLeft:
    'Rectangular table with 1 bench and 1 chair on the left',
  recTable1Bench1SqrChairRight:
    'Rectangular table with 1 bench and 1 chair on the right',
  recTable2Benches2SqrChairs:
    'Rectangular table with 2 benches and 2 square chairs',
  recTable2Benches: 'Rectangular table with 2 benches',
  recTable2Couches: 'Rectangular table with 2 couches',
  recTable2LoveSeats: 'Rectangular table with 2 love seats',
  recTable2RndChairsNoBench:
    'Rectangular table with 2 round chairs on one side and no bench',
  recTable2RndChairsOneSide:
    'Rectangular table with 2 round chairs on one side',
  recTable2SqrChairs50PctWrapBench:
    'Rectangular table with 2 square chairs and a 50 percent wrap bench',
  recTable2SqrChairsAndBench:
    'Rectangular table with 2 square chairs and a bench',
  recTable2SqrChairsNoBench:
    'Rectangular table with 2 square chairs on one side and no bench',
  recTable3LoveSeats: 'Rectangular table with 3 love seats (75% wrap)',
  recTable3SeatCouch2ArmChairs:
    'Rectangular table with 3 seat couch and 2 arm chairs',
  recTable3SeatCouch5ArmChairs:
    'Rectangular table with a 3 seat couch and 5 arm chairs',
  recTable3SqrChairs50PctWrapBench:
    'Rectangular table with 3 square chairs and a 50 percent wrap bench',
  recTable3SqrChairsAndBench:
    'Rectangular table with 3 square chairs and a bench',
  recTable4RndChairs: 'Rectangular table with 4 round chairs',
  recTable4SqrChairs: 'Rectangular table with 4 square chairs',
  recTable4SqrChairsWithV: 'Rectangular table with 4 square chairs with V',
  recTable5SqrChairs50PctWrapBench:
    'Rectangular table with 5 square chairs and a 50 percent wrap bench',
  recTable5SqrChairs: 'Rectangular table with 5 square chairs',
  recTable6RndChairs: 'Rectangular table with 6 round chairs',
  recTable6SqrChairs: 'Rectangular table with 6 square chairs',
  recTable6SqrChairsV2: 'V2 of Rectangular table with 6 square chairs',
  recTable8RndChairs: 'Rectangular table with 8 round chairs',
  recTableLong3SqrChairs50PctWrapBench:
    'Long Rectangular table with 3 square chairs and a 50 percent wrap bench',
  recTableLrgLoveSeat2SqrChairs:
    'Rectangular large table with love seat and 2 square chairs',
  rectTable2LoveSeats2SqrChairs:
    'Rectangular table with a love seat and 2 square chairs',
  rectTable2LoveSeats: 'Rectangular table with 2 love seats',
  rndTable1Bench2ArmChairs: 'Round table with a bench and 2 armchairs',
  rndTable1RndChair: 'Round table with 1 round chair',
  rndTable1SqrChair: 'Round table with 1 square chair',
  rndTable2ArmChairs: 'Round table with 2 armchairs',
  rndTable2PoolChairs: 'Round table with 2 pool chairs',
  rndTable2RndChairs: 'Round table with 2 round chairs',
  rndTable2SeatCouch: 'Round table 2 seat couch',
  rndTable2SqrChairsOneSide: 'Round table with 2 square chairs on one side',
  rndTable3SqrChairs: 'Round table with 3 square chairs',
  rndTable4LoveSeats1ArmChair: 'Round table with 4 love seats 1 arm chair',
  rndTable4RndChairs: 'Round table with 4 round chairs',
  rndTable4SqrChairs: 'Round table with 4 square chairs',
  rndTable4SqrChairsWithV: 'Round table with 4 square chairs with V',
  rndTable50PctBenchWrap1SqrChair:
    'Round table with 50 percent bench wrap 1 square chair',
  rndTable50PctWrapBench: 'Round table with 50 percent wrap bench',
  rndTable50PctWrapCornerBench: 'Round table with 50 percent wrap corner bench',
  rndTable6SqrChairs: 'Round table with 6 square chairs',
  rndTable8RndChairs: 'Round table with 8 round chairs',
  rndTables3SeatCouch2ArmChairs: 'Round table 3 seat couch and 2 arm chars',
  sqrTable1LoveSeat4ArmChairs: 'Square table with 1 love seat and 4 arm chairs',
  sqrTable1RndChair: 'Square table 1 round chair',
  sqrTable1RndChair1SqrChair:
    'Square table with 1 round chair and 1 square chair',
  sqrTable2ArmChairs: 'Square table with 2 arm chairs',
  sqrTable2LoveSeats2ArmChairs:
    'Square table with 2 love seats and 2 arm chairs',
  sqrTable2LoveSeats2ArmChairs1Ottoman:
    'Square table with a love seat, 2 armchairs, and an ottoman',
  sqrTable2LoveSeats2SqrChairs:
    'Square table with a love seat and 2 square chairs',
  sqrTable1RndChairBenchADA:
    'Square table with 1 round chair and a bench and ADA',
  sqrTable1SqrChair: 'Square table 1 square chair',
  sqrTable2ArmChairs6LoveSeats:
    'Squeare table with 2 arm chairs and 3 love seats',
  sqrTable2RndChairs: 'Square table with 2 round chairs',
  sqrTable2SqrChairs: 'Square table with 2 square chairs',
  sqrTable4LoveSeats1ArmChair1Ottoman:
    'Square table with 2 love seats, and armchair, and an ottoman',
  sqrTable4LoveSeats1Ottoman: 'Square table with 2 love seats and an ottoman',
  sqrTable4LoveSeats2Ottomans: 'Square table with 2 love seats and 2 ottomans',
  sqrTable4RndChairs: 'Square table with 4 round chairs',
  sqrTable4SqrChairs: 'Square table with 4 square chairs',
  sqrTable50PctWrapBench: 'Square table with 50 percent wrap bench',
  sqrTable75PctWrapBench: 'Square table with 75 percent wrap bench',
  sqrTable75PctWrapCornerBench:
    'Square table with 75 percent wrap corner bench',
  twoRndTable3SeatCouch2LoveSeats1ArmChair:
    '2 round tables with a 3 seat couch, 2 love seats, and an arm chair',
  twoRndTablesTwo4SeatCouches: '2 round tables with 2 love seats',
  twoSqrTable2LoveSeats3ArmChairs:
    '2 square tables with 2 love seats and 3 arm chairs',
  wideLoungeCouch: 'Wide lounge couch',
};

export const getDefaultTableMetadata = (iconName: IconNameType) => {
  switch (iconName) {
    // 1 tops
    case 'armChair':
    case 'barSeat':
    case 'barSeatSmall':
      return {
        minimumGuests: 1,
        maximumGuests: 1,
        turnTime: 60,
      };
    // 2 tops
    case 'loveSeat':
    case 'ovlTable2Armchairs':
    case 'ovlTable2LoveSeats':
    case 'recTable2RndChairsOneSide':
    case 'rectTable2LoveSeats':
    case 'rndTable2ArmChairs':
    case 'rndTable2PoolChairs':
    case 'rndTable2RndChairs':
    case 'rndTable2SeatCouch':
    case 'sqrTable2ArmChairs':
    case 'sqrTable1RndChair1SqrChair':
    case 'sqrTable1RndChairBenchADA':
    case 'sqrTable2RndChairs': // issue
    case 'sqrTable2SqrChairs':
    case 'rndTable1RndChair':
    case 'rndTable1SqrChair':
    case 'sqrTable1RndChair':
    case 'sqrTable1SqrChair':
    case 'wideLoungeCouch':
      return {
        minimumGuests: 1,
        maximumGuests: 2,
        turnTime: 60,
      };
    // 3tops
    case 'recTable1Bench1SqrChairLeft':
    case 'recTable1Bench1SqrChairRight':
    case 'rndTable3SqrChairs':
    case 'rndTable50PctWrapCornerBench':
    case 'sqrTable50PctWrapBench':
      return {
        minimumGuests: 2,
        maximumGuests: 3,
        turnTime: 90,
      };
    // 4 tops
    case 'ovlTable4Armchairs':
    case 'recTable2LoveSeats':
    case 'recTable2SqrChairsAndBench':
    case 'recTable4RndChairs':
    case 'recTable4SqrChairs':
    case 'recTable4SqrChairsWithV':
    case 'recTableLrgLoveSeat2SqrChairs':
    case 'rectTable2LoveSeats2SqrChairs':
    case 'rndTable1Bench2ArmChairs':
    case 'rndTable2SqrChairsOneSide':
    case 'rndTable4RndChairs':
    case 'rndTable4SqrChairs':
    case 'rndTable4SqrChairsWithV':
    case 'rndTable50PctWrapBench':
    case 'sqrTable2LoveSeats2SqrChairs':
    case 'sqrTable4RndChairs':
    case 'sqrTable4SqrChairs':
    case 'recTable2SqrChairsNoBench':
    case 'recTable2RndChairsNoBench':
      return {
        minimumGuests: 2,
        maximumGuests: 4,
        turnTime: 90,
      };
    // 5 tops
    case 'recTable2SqrChairs50PctWrapBench':
    case 'recTable3SeatCouch2ArmChairs':
    case 'recTable5SqrChairs':
    case 'rndTable4LoveSeats1ArmChair':
    case 'rndTable50PctBenchWrap1SqrChair':
    case 'rndTables3SeatCouch2ArmChairs':
    case 'sqrTable2LoveSeats2ArmChairs1Ottoman':
    case 'sqrTable4LoveSeats1Ottoman':
    case 'sqrTable75PctWrapBench':
    case 'sqrTable75PctWrapCornerBench':
      return {
        minimumGuests: 3,
        maximumGuests: 5,
        turnTime: 120,
      };
    // 6 tops
    case 'ovlTable2Benches':
    case 'ovlTable6RndChairs':
    case 'recTable2Benches':
    case 'recTable2Couches':
    case 'recTable3LoveSeats':
    case 'recTable3SqrChairs50PctWrapBench':
    case 'recTable3SqrChairsAndBench':
    case 'recTable6RndChairs':
    case 'recTable6SqrChairs':
    case 'recTable6SqrChairsV2':
    case 'rndTable6SqrChairs':
    case 'sqrTable1LoveSeat4ArmChairs':
    case 'sqrTable2LoveSeats2ArmChairs':
    case 'sqrTable4LoveSeats1ArmChair1Ottoman':
    case 'sqrTable4LoveSeats2Ottomans':
    case 'twoRndTable3SeatCouch2LoveSeats1ArmChair':
      return {
        minimumGuests: 4,
        maximumGuests: 6,
        turnTime: 120,
      };
    // 7tops
    case 'twoSqrTable2LoveSeats3ArmChairs':
    case 'recTableLong3SqrChairs50PctWrapBench':
      return {
        minimumGuests: 4,
        maximumGuests: 7,
        turnTime: 150,
      };
    // 8tops
    case 'recTable2Benches2SqrChairs':
    case 'recTable3SeatCouch5ArmChairs':
    case 'recTable8RndChairs':
    case 'rndTable8RndChairs':
    case 'twoRndTablesTwo4SeatCouches':
    case 'sqrTable2ArmChairs6LoveSeats':
      return {
        minimumGuests: 6,
        maximumGuests: 8,
        turnTime: 150,
      };
    // 10tops
    case 'cabanaTable':
    case 'recTable10SqrChairs':
      return {
        minimumGuests: 8,
        maximumGuests: 10,
        turnTime: 180,
      };
    // 11tops
    case 'recTable5SqrChairs50PctWrapBench':
      return {
        minimumGuests: 8,
        maximumGuests: 11,
        turnTime: 210,
      };
    case 'mergedTable':
    default:
      return {
        minimumGuests: 1,
        maximumGuests: 4,
        turnTime: 90,
      };
  }
};

export const ICON_NAMES = Object.keys(HUMAN_READABLE_ICON_NAMES);

export type IconNameType = keyof typeof HUMAN_READABLE_ICON_NAMES;

export interface ListingId {
  id: string;
}

export interface FloorPlanTableData {
  iconName: IconNameType;
  iconWidthScale: string;
  id: string;
  left: string;
  maximumGuests: number;
  minimumGuests: number;
  name: string;
  orientation: string;
  pulse?: boolean;
  listings: ListingId[];
  top: string;
  turnTime: number;
}

export interface FloorPlanData {
  id: string;
  backgroundSrc?: string;
  name: string;
  floorPlanTables: FloorPlanTableData[];
}

export interface FloorPlanSnapshotTableData {
  iconName: IconNameType;
  iconWidthScale: string;
  id: string;
  isHighlighted: boolean;
  left: string;
  name: string;
  orientation: string;
  top: string;
}

export interface FloorPlanSnapshotData {
  backgroundSrc: string;
  floorPlanTables: FloorPlanSnapshotTableData[];
}
