// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ConciergeAdminAvailability__container___qwgCu{background-color:var(--darkBlue);border-radius:var(--radius_large);display:flex;flex-direction:column;gap:1.5rem;margin:0;min-width:15rem;overflow-y:auto;padding:0 1rem 1rem;scroll-snap-type:y mandatory}.ConciergeAdminAvailability__comingSoon___oOEKh{color:var(--white);margin-bottom:3rem;margin-top:2.5rem;text-align:center}.ConciergeAdminAvailability__comingSoon___oOEKh .ConciergeAdminAvailability__comingSoonHeader___mJfWn{font-family:\"PP Woodland\",sans-serif}.ConciergeAdminAvailability__comingSoon___oOEKh .ConciergeAdminAvailability__comingSoonMessage___UKFS8{color:var(--secondary200);font-size:1rem;letter-spacing:.03em;line-height:1.5rem}.ConciergeAdminAvailability__list___PKCVO{display:flex;flex-direction:column;padding-inline-start:0;row-gap:.5rem;width:100%}", "",{"version":3,"sources":["webpack://./src/restaurantAdmin/reservations/concierge/ConciergeAdminAvailability.scss"],"names":[],"mappings":"AAEA,+CACE,gCAAA,CACA,iCAAA,CACA,YAAA,CACA,qBAAA,CACA,UAAA,CACA,QAAA,CACA,eAAA,CACA,eAAA,CACA,mBAAA,CACA,4BAAA,CAGF,gDACE,kBAAA,CACA,kBAAA,CACA,iBAAA,CACA,iBAAA,CAEA,sGACE,oCAAA,CAGF,uGACE,yBAAA,CACA,cAAA,CACA,oBAAA,CACA,kBAAA,CAIJ,0CACE,YAAA,CACA,qBAAA,CACA,sBAAA,CACA,aAAA,CACA,UAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ConciergeAdminAvailability__container___qwgCu",
	"comingSoon": "ConciergeAdminAvailability__comingSoon___oOEKh",
	"comingSoonHeader": "ConciergeAdminAvailability__comingSoonHeader___mJfWn",
	"comingSoonMessage": "ConciergeAdminAvailability__comingSoonMessage___UKFS8",
	"list": "ConciergeAdminAvailability__list___PKCVO"
};
export default ___CSS_LOADER_EXPORT___;
