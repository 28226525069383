import cx from 'classnames';
import { type ReactNode } from 'react';
import { Icon } from '@components/icon/Icon';
import { useHandleClickOutside } from '@shared/hooks/useHandleClickOutside';
import {
  parseToDayMonthFormat,
  toISODateFormat,
} from '@shared/utils/dateFormatters';
import { appendGuestCountLabel } from '@utils/guestCount';
import typography from '~styles/typography.scss';
import styles from './DateAndGuestCountFilterPill.scss';

export interface DateAndGuestCountFilterPillProps {
  closeFilter: () => void;
  isOpenFilter: boolean;
  onFilterOpen?: () => void;
  openFilter: () => void;
  selectedDate: Date;
  selectedGuestCount: number;
  popover: ReactNode;
}

export const DateAndGuestCountFilterPill = ({
  closeFilter,
  isOpenFilter,
  onFilterOpen,
  openFilter,
  selectedDate,
  selectedGuestCount,
  popover,
}: DateAndGuestCountFilterPillProps) => {
  const togglePopoverVisibility = () => {
    if (isOpenFilter) {
      closeFilter();
    } else {
      openFilter();
      if (onFilterOpen) onFilterOpen();
    }
  };

  const { ref } = useHandleClickOutside(closeFilter);

  const pillClassNames = cx(typography.t1, styles.pill, {
    [styles.isOpen]: isOpenFilter,
  });

  const ariaLabel = `${appendGuestCountLabel(
    selectedGuestCount,
  )}, ${parseToDayMonthFormat(toISODateFormat(selectedDate))}. Filter by date and guest count`;

  return (
    <div ref={ref}>
      <button
        aria-label={ariaLabel}
        className={pillClassNames}
        onClick={togglePopoverVisibility}
      >
        <Icon className={styles.icon} name="couple" />
        <span className={cx(typography.t1, styles.guestCount)}>
          {selectedGuestCount}
        </span>
        <span className={styles.verticalDivider} />
        <Icon className={styles.icon} name="calendar" />
        {parseToDayMonthFormat(toISODateFormat(selectedDate))}
      </button>
      {isOpenFilter && popover}
    </div>
  );
};
