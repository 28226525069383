import { isPast, isToday } from 'date-fns';
import { useCallback, useEffect, useState } from 'react';
import { useError } from '@shared/hooks/useError';
import { reportAppError } from '@shared/reportAppError';
import { toISODateFormat } from '@shared/utils/dateFormatters';
import { useRestaurant } from '../../../context/useRestaurant';
import * as reservationsApiHelpers from '../../apiHelpers';
import * as apiHelpers from '../apiHelpers';
import { useOccupantContext } from '../state/OccupantContext';

export interface CloseRestaurantHookReturn {
  canCloseRestaurant: boolean;
  canOpenRestaurant: boolean;
  closeRestaurant: (lastReservationTime?: string) => Promise<void>;
  openRestaurant: () => Promise<void>;
}

export const useCloseRestaurant = (): CloseRestaurantHookReturn => {
  const { id: restaurantId } = useRestaurant();
  const { reservationDate } = useOccupantContext();
  const setError = useError();

  const [isRestaurantClosed, setIsRestaurantClosed] = useState(false);
  const dateToFetch = toISODateFormat(reservationDate);

  useEffect(() => {
    const checkRestaurantIsClosed = async () => {
      try {
        const isRestaurantClosedResponse =
          await reservationsApiHelpers.isRestaurantClosed(
            restaurantId,
            dateToFetch,
          );

        setIsRestaurantClosed(isRestaurantClosedResponse);
      } catch (e) {
        setError(e);
      }
    };

    checkRestaurantIsClosed();
  }, [restaurantId, dateToFetch]);

  const closeRestaurant = useCallback(
    async (lastReservationTime?: string) => {
      try {
        await apiHelpers.closeRestaurant(
          restaurantId,
          dateToFetch,
          lastReservationTime,
        );
        setIsRestaurantClosed(true);
      } catch (error) {
        reportAppError(error);
        setError(error);
      }
    },
    [restaurantId, dateToFetch],
  );

  const openRestaurant = useCallback(async () => {
    try {
      await apiHelpers.openRestaurant(restaurantId, dateToFetch);
      setIsRestaurantClosed(false);
    } catch (error) {
      reportAppError(error);
      setError(error);
    }
  }, [restaurantId, dateToFetch]);

  const reservationDateHasNotPassed =
    !isPast(reservationDate) || isToday(reservationDate);

  const canCloseRestaurant = reservationDateHasNotPassed && !isRestaurantClosed;
  const canOpenRestaurant = reservationDateHasNotPassed && isRestaurantClosed;

  return {
    canCloseRestaurant,
    canOpenRestaurant,
    closeRestaurant,
    openRestaurant,
  };
};
