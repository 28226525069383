import type { FloorPlanTablesRenderer } from '@components/floorPlan/FloorPlan';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import { type TableTimerDictionary } from '../state/ReservationServiceContext';
import type { FloorPlanOccupant } from '../state/types';
import { OccupantFloorPlanTableFactory } from './OccupantFloorPlanTableFactory';

export interface OccupantFloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  tables: HostFloorPlanTable[];
  seatedTableOnClick: (occupant: FloorPlanOccupant) => void;
  emptyTableOnClick: (floorPlanTable: HostFloorPlanTable) => void;
  tableTimersByFloorPlanTableId: TableTimerDictionary;
  selectedEmptyTableId?: string;
}

export const OccupantFloorPlan = ({
  backgroundSrc,
  className,
  tables,
  seatedTableOnClick,
  emptyTableOnClick,
  selectedEmptyTableId,
  tableTimersByFloorPlanTableId,
}: OccupantFloorPlanProps) => {
  const floorPlanTablesRenderer: FloorPlanTablesRenderer = (tableIconScale) => (
    <>
      {tables.map((floorPlanTable) => (
        <OccupantFloorPlanTableFactory
          floorPlanTable={floorPlanTable}
          handleTableOnClick={emptyTableOnClick}
          key={floorPlanTable.id}
          seatedTableOnClick={seatedTableOnClick}
          selectedEmptyTableId={selectedEmptyTableId}
          timer={tableTimersByFloorPlanTableId[floorPlanTable.id] || null}
          tableIconScale={tableIconScale}
        />
      ))}
    </>
  );

  return (
    <FloorPlan
      backgroundSrc={backgroundSrc}
      className={className}
      floorPlanTablesRenderer={floorPlanTablesRenderer}
    />
  );
};
