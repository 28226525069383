import { kebabCase } from 'lodash-es';
import {
  FloorPlan,
  type FloorPlanTablesRenderer,
} from '@components/floorPlan/FloorPlan';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import {
  isPersistedPrimaryMergedTable,
  isPersistedSecondaryMergedTable,
} from 'restaurantAdmin/floorPlans/utils';
import { useSplitMergeTables } from 'restaurantAdmin/reservations/splitMergeTables/state/SplitMergeTablesContext';
import { SeatedFloorPlanTable } from '../service/floorPlan/SeatedFloorPlanTable';
import { UnoccupiedFloorPlanTable } from '../service/floorPlan/UnoccupiedFloorPlanTable';
import { type TableTimerDictionary } from '../service/state/ReservationServiceContext';

export interface SplitMergeFloorPlanProps {
  tableTimersByFloorPlanTableId: TableTimerDictionary;
  backgroundSrc?: string;
  className?: string;
  tables: HostFloorPlanTable[];
}

export const SplitMergeFloorPlan = ({
  tableTimersByFloorPlanTableId,
  backgroundSrc,
  className,
  tables,
}: SplitMergeFloorPlanProps) => {
  const {
    isStagedSecondaryMergedTableId,
    isStagedPrimaryMergedTableId,
    isStagedForUnmerge,
    isSelectedForMerge,
    selectTableForMerge,
    unselectTableForMerge,
  } = useSplitMergeTables();

  const handleTableOnClick = (floorPlanTable: HostFloorPlanTable) => {
    if (isSelectedForMerge(floorPlanTable.id)) {
      unselectTableForMerge(floorPlanTable.id);
    } else {
      selectTableForMerge(floorPlanTable);
    }
  };

  const mergeAwareTables = tables
    .map((table) => {
      if (isStagedPrimaryMergedTableId(table.id)) {
        return {
          ...table,
          iconName: 'mergedTable',
          orientation: '0.00',
        };
      }
      if (
        isPersistedPrimaryMergedTable(table) &&
        !isStagedForUnmerge(table.id)
      ) {
        return {
          ...table,
          iconName: 'mergedTable',
          orientation: '0.00',
        };
      }
      if (isStagedSecondaryMergedTableId(table.id)) {
        // hidden from floor plan
        return null;
      }
      if (
        isPersistedSecondaryMergedTable(table) &&
        !isStagedForUnmerge(table.mergeTableId)
      )
        // hidden from floor plan if associated with a primary merged table
        return null;
      return table;
    })
    .filter((table): table is HostFloorPlanTable => table !== null);

  const floorPlanTablesRenderer: FloorPlanTablesRenderer = (tableIconScale) => (
    <>
      {mergeAwareTables.map((floorPlanTable) => {
        const testId = kebabCase(`floor-plan-table-${floorPlanTable.name}`);
        if (isStagedSecondaryMergedTableId(floorPlanTable.id)) return null;
        if (
          isPersistedSecondaryMergedTable(floorPlanTable) &&
          !isStagedForUnmerge(floorPlanTable.mergeTableId)
        )
          return null;
        if (floorPlanTable.seatedOccupant) {
          return (
            <SeatedFloorPlanTable
              iconName={floorPlanTable.iconName}
              iconWidthScale={floorPlanTable.iconWidthScale}
              isAnyTableHighlighted={false}
              isDisabled
              isHighlighted={false}
              key={floorPlanTable.id}
              left={floorPlanTable.left}
              name={floorPlanTable.name}
              orientation={floorPlanTable.orientation}
              seatedOccupant={floorPlanTable.seatedOccupant}
              tableIconScale={tableIconScale}
              tableName={floorPlanTable.name}
              testId={testId}
              top={floorPlanTable.top}
            />
          );
        }
        return (
          <UnoccupiedFloorPlanTable
            timer={tableTimersByFloorPlanTableId[floorPlanTable.id]}
            handleTableOnClick={() => {
              void handleTableOnClick(floorPlanTable);
            }}
            iconWidthScale={floorPlanTable.iconWidthScale}
            isDisabled={false}
            iconName={floorPlanTable.iconName}
            isHighlighted={isSelectedForMerge(floorPlanTable.id)}
            isSelected={false}
            key={floorPlanTable.id}
            left={floorPlanTable.left}
            name={floorPlanTable.name}
            orientation={floorPlanTable.orientation}
            tableIconScale={tableIconScale}
            testId={testId}
            top={floorPlanTable.top}
          />
        );
      })}
    </>
  );

  return (
    <FloorPlan
      backgroundSrc={backgroundSrc}
      className={className}
      floorPlanTablesRenderer={floorPlanTablesRenderer}
      testId="merge-unmerge-floor-plan"
    />
  );
};
