import { type Dispatch, type SetStateAction, useState } from 'react';
import { useAbortEffect } from '@shared/hooks/useAbortEffect';
import { type FloorPlanData } from '@shared/types/floorPlans';
import {
  getFloorPlansForRestaurantId,
  getFloorPlansForRestaurantIdWithDraftListings,
} from 'restaurantAdmin/floorPlans/apiHelpers';
import { useRestaurant } from '../context/useRestaurant';

interface UseAdminFloorPlanReturn {
  floorPlans: FloorPlanData[];
  isLoading: boolean;
  fetchFloorPlans: () => void;
  selectedFloorPlan: FloorPlanData;
  setSelectedFloorPlanId: Dispatch<SetStateAction<string>>;
}

const NULL_FLOOR_PLAN = {
  id: '',
  backgroundSrc: undefined,
  floorPlanTables: [],
  name: '',
};

export const useAdminFloorPlans = (
  activeListingsOnly: boolean,
): UseAdminFloorPlanReturn => {
  const { id: restaurantId } = useRestaurant();
  const [selectedFloorPlanId, setSelectedFloorPlanId] = useState<string>('');

  const {
    data: floorPlans = [],
    isPending: isLoading,
    rerun: fetchFloorPlans,
  } = useAbortEffect(
    {
      effect: async () => {
        try {
          const allFloorPlans = activeListingsOnly
            ? await getFloorPlansForRestaurantId(restaurantId, true)
            : await getFloorPlansForRestaurantIdWithDraftListings(restaurantId);
          if (!selectedFloorPlan.id) {
            setSelectedFloorPlanId(allFloorPlans[0].id);
          }
          return allFloorPlans;
        } catch {
          return [NULL_FLOOR_PLAN];
        }
      },
      throwOnError: false,
    },
    [restaurantId, activeListingsOnly],
  );

  const selectedFloorPlan =
    floorPlans.find((fp) => fp.id === selectedFloorPlanId) || NULL_FLOOR_PLAN;

  return {
    floorPlans,
    isLoading,
    fetchFloorPlans,
    selectedFloorPlan,
    setSelectedFloorPlanId,
  };
};
