import { Option } from '@mui/base/Option';
import { Select } from '@mui/base/Select';
import cx from 'classnames';
import { useState } from 'react';
import { errorToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../context/useRestaurant';
import {
  createReservationServiceStatus,
  createWalkInServiceStatus,
} from '../apiHelpers';
import { type OccupantType } from '../state/types';
import {
  SERVICE_STATUS_LABELS,
  SERVICE_STATUS_SELECTION_LIST,
  type ServiceStatus,
} from './ServiceStatus';
import { ServiceStatusIcon } from './ServiceStatusIcon';
import styles from './ServiceStatusSelect.scss';

export interface ServiceStatusSelectProps {
  occupantType: OccupantType;
  occupantId: string;
  showLabel?: boolean;
  value: ServiceStatus;
}

export const ServiceStatusSelect = ({
  occupantType,
  occupantId,
  showLabel = false,
  value,
}: ServiceStatusSelectProps) => {
  const { id: restaurantId } = useRestaurant();
  const [isLoading, setIsLoading] = useState(false);
  const { createServiceStatus } = occupantTypeHelpers[occupantType];

  const onChange = (_event: unknown, newValue: ServiceStatus | null) => {
    if (!newValue) return;
    setIsLoading(true);
    void (async () => {
      try {
        await createServiceStatus(restaurantId, occupantId, newValue);
      } catch (e) {
        errorToast({
          message: `Failed to update the service status to "${SERVICE_STATUS_LABELS[newValue]}"`,
        });
        reportAppError(e);
      } finally {
        setIsLoading(false);
      }
    })();
  };

  return (
    <div className={styles.main}>
      <Select<ServiceStatus>
        aria-label="Service Status"
        defaultValue={value}
        disabled={isLoading}
        onChange={onChange}
        renderValue={(val) => {
          if (val && showLabel) {
            return (
              <div className={styles.iconWithLabel}>
                <ServiceStatusIcon serviceStatus={val.value} />
                <span className={cx(typography.t3, styles.statusLabel)}>
                  {SERVICE_STATUS_LABELS[val.value]}
                </span>
              </div>
            );
          }
          if (val) {
            return <ServiceStatusIcon serviceStatus={val.value} />;
          }
          return null;
        }}
        slotProps={{
          root: { className: styles.combobox },
          popup: { disablePortal: true, style: { zIndex: 10 } },
          listbox: { className: styles.listbox },
        }}
        value={value}
      >
        {SERVICE_STATUS_SELECTION_LIST.map((status) =>
          status === 'seated' ? (
            <Option aria-hidden value={status} key={status} />
          ) : (
            <Option value={status} className={styles.option} key={status}>
              <ServiceStatusIcon aria-hidden serviceStatus={status} />
              <span className={cx(typography.t1, styles.statusName)}>
                {SERVICE_STATUS_LABELS[status]}
              </span>
            </Option>
          ),
        )}
      </Select>
    </div>
  );
};

const occupantTypeHelpers: Record<
  OccupantType,
  {
    createServiceStatus: (
      restaurantId: string,
      occupantId: string,
      serviceStatus: ServiceStatus,
    ) => Promise<void>;
  }
> = {
  reservation: {
    createServiceStatus: createReservationServiceStatus,
  },
  walkIn: {
    createServiceStatus: createWalkInServiceStatus,
  },
} as const;
