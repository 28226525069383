import cx from 'classnames';
import { useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import { Card } from '@components/card/Card';
import { Select } from '@components/select/Select';
import { PageContent } from 'restaurantAdmin/layout/PageContent';
import typography from '~styles/typography.scss';
import {
  OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_INACTIVE_PATH,
  OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH,
} from '../../../paths';
import { InteractiveAdminFloorPlan } from '../floorPlan/InteractiveAdminFloorPlan';
import { useListingsContext } from '../ListingsContext';
import { ListingDetailsSheet } from './ListingDetailsSheet';
import { ListingsList } from './ListingsList';
import styles from './ListingsPage.scss';
import { ListingsPageHeader } from './ListingsPageHeader';

export const ListingsFloorPlanPage = () => {
  const {
    floorPlans,
    isLoading,
    listings,
    selectedFloorPlan,
    selectedFloorPlanTableListingIds,
    selectedListing,
    setSelectedFloorPlanId,
  } = useListingsContext();

  const tabClassNames = ({ isActive }: { isActive: boolean }) =>
    cx({
      [styles.tab]: true,
      [styles.activeTab]: isActive,
      [typography.t1]: true,
    });

  const isTabsVisible = !selectedListing;

  const filteredListings = useMemo(
    () =>
      listings.filter(
        (listing) =>
          selectedFloorPlan.id === listing.floorPlanId &&
          (!selectedFloorPlanTableListingIds.length ||
            selectedFloorPlanTableListingIds.includes(listing.id)),
      ),
    [listings, selectedFloorPlan, selectedFloorPlanTableListingIds],
  );

  if (isLoading) return null;

  return (
    <>
      <ListingsPageHeader />
      <PageContent className={styles.content}>
        <Card
          className={cx({
            [styles.displayPanel]: true,
            [styles.alignStart]: true,
            [styles.floorPlan]: true,
          })}
        >
          {floorPlans.length > 1 && (
            <Select
              className={styles.floorPlanSelect}
              onChange={(_, value) => value && setSelectedFloorPlanId(value)}
              options={floorPlans.map((fp) => ({
                label: fp.name,
                value: fp.id,
              }))}
              value={selectedFloorPlan.id}
            />
          )}
          <InteractiveAdminFloorPlan
            backgroundSrc={selectedFloorPlan.backgroundSrc}
            tables={selectedFloorPlan.floorPlanTables}
          />
        </Card>
        <div className={styles.sidePanel}>
          <div
            className={styles.tabs}
            data-testid="tabs"
            style={{ display: isTabsVisible ? undefined : 'none' }}
          >
            <div className={styles.tabBar}>
              <NavLink
                className={tabClassNames}
                to={OPERATIONS_LISTINGS_FLOOR_PLAN_PUBLISHED_PATH}
              >
                Published
              </NavLink>
              <NavLink
                className={tabClassNames}
                to={OPERATIONS_LISTINGS_FLOOR_PLAN_DRAFT_PATH}
              >
                Draft
              </NavLink>
              <NavLink
                className={tabClassNames}
                to={OPERATIONS_LISTINGS_FLOOR_PLAN_INACTIVE_PATH}
              >
                Inactive
              </NavLink>
            </div>
            <ListingsList filteredListings={filteredListings} />
          </div>
          {selectedListing && (
            <ListingDetailsSheet filteredListings={filteredListings} />
          )}
        </div>
      </PageContent>
    </>
  );
};
