import cx from 'classnames';
import { centsToCompactDollar } from '@shared/utils/currency';
import { ISOTimeTo12HourTime } from '@shared/utils/time';
import typography from '~styles/typography.scss';
import styles from './AvailableTimeButton.scss';

interface AvailableTimeButtonProps {
  onClick: () => void;
  purchasePrice: number | null;
  time: string;
}

const OFFER = 'OFFER';

export const AvailableTimeButton = ({
  onClick,
  purchasePrice,
  time,
}: AvailableTimeButtonProps) => {
  const priceLabel =
    purchasePrice !== null ? centsToCompactDollar(purchasePrice) : OFFER;

  return (
    <button
      aria-label={`Select ${ISOTimeTo12HourTime(time)}`}
      className={cx(typography.c3_20, styles.container)}
      onClick={onClick}
    >
      {ISOTimeTo12HourTime(time)}
      <span className={typography.t3}>{priceLabel}</span>
    </button>
  );
};
