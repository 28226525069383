import { useForm } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormTimeSelect } from '@components/formInputs/ControlledFormTimeSelect';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';

interface CloseRestaurantModalProps {
  closeModal: () => void;
  onConfirm: (lastReservationTime?: string) => void;
  isOpen: boolean;
}

export interface CloseRestaurantFormData {
  lastReservationTime?: string;
}

export const CloseRestaurantModal = ({
  closeModal,
  onConfirm,
  isOpen,
}: CloseRestaurantModalProps) => {
  const { control, handleSubmit } = useForm<CloseRestaurantFormData>({
    defaultValues: {
      lastReservationTime: undefined,
    },
  });

  const handleOnSubmit = (data: CloseRestaurantFormData) => {
    onConfirm(data.lastReservationTime);
  };

  return isOpen ? (
    <Modal
      ariaLabel="Close restaurant"
      isOpen={isOpen}
      onClose={closeModal}
      subtitle="Are you sure you want to close the restaurant? Any existing
      reservations for this date will not be canceled."
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <ControlledFormTimeSelect
          control={control}
          label="Last Reservation Time (optional)"
          name="lastReservationTime"
          variant={LayoutVariant.Horizontal}
          rules={{
            required: false,
          }}
        />
        <ModalActions>
          <Button
            label="Cancel"
            onClick={closeModal}
            variant={ButtonVariants.Tertiary}
          />
          <Button
            label="Confirm"
            type="submit"
            variant={ButtonVariants.Primary}
          />
        </ModalActions>
      </form>
    </Modal>
  ) : null;
};
