import cx from 'classnames';
import { secondsToMilliseconds } from 'date-fns';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonVariants } from '@components/button/Button';
import { ExternalRefundLink } from '@components/externalRefundLink/ExternalRefundLink';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { reportAppError } from '@shared/reportAppError';
import { centsToDollar } from '@shared/utils/currency';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import {
  cancel,
  waiveCancellationFee,
} from 'restaurantAdmin/reservations/apiHelpers';
import typography from '~styles/typography.scss';
import styles from './CancelConfirmationModal.scss';

export interface CancelAndWaiveFeeConfirmationModalProps {
  cancellationPolicy: {
    fee: number;
  };
  closeModal: () => void;
  disablePortal?: boolean;
  handleAfterConfirm?: () => void;
  isOpen: boolean;
  reservationId: string;
}

const CURRENT_PAGE = 0;

export const CancelAndWaiveFeeConfirmationModal = ({
  cancellationPolicy,
  closeModal,
  disablePortal,
  handleAfterConfirm,
  isOpen,
  reservationId,
}: CancelAndWaiveFeeConfirmationModalProps) => {
  const restaurant = useRestaurant();
  const navigate = useNavigate();

  const [isPending, setIsPending] = useState(false);

  const refreshPage = () =>
    setTimeout(() => navigate(CURRENT_PAGE), secondsToMilliseconds(3));

  const handleCancelAndCollectFee = () => {
    setIsPending(true);

    void (async () => {
      try {
        await cancel(restaurant.id, reservationId);
        successToast({
          message:
            "Success: The guest's reservation has been successfully canceled.",
        });

        await waiveCancellationFee(restaurant.id, reservationId);
        successToast({
          message:
            "Success: The guest's reservation cancellation fee has been waived.",
        });
      } catch (error) {
        if (error instanceof Error) {
          errorToast({
            message: `Failed to cancel reservation or waive fee: ${error.message}`,
          });
        } else {
          errorToast({
            message:
              'Failed to cancel reservation or waive fee. Please try again.',
          });
        }
        reportAppError(error);
      }
      if (handleAfterConfirm) {
        handleAfterConfirm();
      } else {
        refreshPage();
      }
    })();
  };

  return (
    <Modal
      ariaLabel="Cancel and Waive Fee Confirmation"
      isOpen={isOpen}
      onClose={closeModal}
      disablePortal={disablePortal}
    >
      <h1 className={typography.d3}>Cancel Reservation</h1>
      <p className={cx(typography.c2, styles.message)}>
        Click confirm to cancel this reservation and waive the{' '}
        {centsToDollar(cancellationPolicy?.fee)} cancellation fee.
      </p>
      <p className={cx(typography.c2, styles.message)}>
        Please reference the Peak <ExternalRefundLink className={styles.link} />{' '}
        for more detail regarding cancellations.
      </p>
      <ModalActions>
        <Button
          label="Go Back"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          isDisabled={isPending}
          label="Confirm"
          onClick={handleCancelAndCollectFee}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
