import { TZDate } from '@date-fns/tz';
import { Select } from '@components/select/Select';
import { StripeElements } from '@components/stripe/StripeElements';
import { DateAndGuestCountFilterPill } from '@shared/availability/DateAndGuestCountFilterPill';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { toISODateFormat } from '@shared/utils/dateFormatters';
import { FilterPopover } from 'restaurantAdmin/components/dateAndGuestCountFilterPill/FilterPopover';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { useAvailabilityDrawerContext } from 'restaurantAdmin/reservations/concierge/state/AvailabilityDrawerContext';
import { PageContent } from '../../layout/PageContent';
import { PageHeader } from '../../layout/PageHeader';
import { ConciergeAdminAvailability } from './ConciergeAdminAvailability';
import { useConciergeAvailabilityContext } from './ConciergeAvailabilityContext';
import { ConciergeDrawer } from './ConciergeDrawer';
import styles from './ConciergePage.scss';
import { InteractiveConciergeFloorPlan } from './InteractiveConciergeFloorPlan';
import { ReserveButton } from './ReserveButton';

export const ConciergePage = () => {
  const { isOpen: isAvailabilityDrawerOpen, openDrawer } =
    useAvailabilityDrawerContext();
  const { timezone } = useRestaurant();
  const {
    clearSelectedAvailability,
    isLoading,
    selectedAvailability,
    selectedDate,
    selectedGuestCount,
    setSelectedDate,
    setSelectedGuestCount,
    selectedFloorPlan,
    floorPlans,
    setSelectedFloorPlanId,
  } = useConciergeAvailabilityContext();
  const {
    close: closeGuestCountAndDateFilter,
    isOpen: isGuestCountAndDateFilterOpen,
    open: openGuestCountAndDateFilter,
  } = useIsOpen();

  const handleReserveClick = () => {
    if (selectedAvailability) {
      openDrawer({
        date: toISODateFormat(selectedDate),
        guestCount: selectedGuestCount,
        listingPrice: selectedAvailability.purchasePrice,
        listingId: selectedAvailability.listing.id,
        time: selectedAvailability.time,
      });
    }
  };

  const handleFilterApply = (appliedDate: Date, appliedGuestCount: number) => {
    setSelectedDate(appliedDate);
    setSelectedGuestCount(appliedGuestCount);
    closeGuestCountAndDateFilter();
    clearSelectedAvailability();
  };

  const renderContent = () => {
    if (isLoading) return null;
    return (
      <>
        <div className={styles.content}>
          <div className={styles.sidePanel}>
            <DateAndGuestCountFilterPill
              closeFilter={closeGuestCountAndDateFilter}
              isOpenFilter={isGuestCountAndDateFilterOpen}
              openFilter={openGuestCountAndDateFilter}
              selectedDate={selectedDate}
              selectedGuestCount={selectedGuestCount}
              popover={
                <FilterPopover
                  onApply={handleFilterApply}
                  selectedDate={selectedDate}
                  selectedGuestCount={selectedGuestCount}
                  minimumDate={TZDate.tz(timezone)}
                />
              }
            />
            <ConciergeAdminAvailability
              availabilityItemAction={
                <ReserveButton handleClick={handleReserveClick} />
              }
            />
          </div>
          <div className={styles.floorPlanContainer}>
            {floorPlans.length > 1 && (
              <Select
                aria-label="floor plan selector"
                className={styles.floorPlanSelector}
                options={floorPlans.map((fp) => ({
                  label: fp.name,
                  value: fp.id,
                }))}
                value={selectedFloorPlan.id}
                onChange={(_, floorPlanId) => {
                  setSelectedFloorPlanId(floorPlanId!);
                }}
              />
            )}
            <InteractiveConciergeFloorPlan
              backgroundSrc={selectedFloorPlan.backgroundSrc}
              tables={selectedFloorPlan.floorPlanTables}
            />
          </div>
        </div>
        {isAvailabilityDrawerOpen && <ConciergeDrawer />}
      </>
    );
  };

  return (
    <StripeElements>
      <PageHeader category="Reservations" title="Concierge" />
      <PageContent className={styles.pageContent}>
        {renderContent()}
      </PageContent>
    </StripeElements>
  );
};
