import { type DragEndEvent, useDndMonitor } from '@dnd-kit/core';
import {
  FloorPlan,
  type FloorPlanTablesRenderer,
} from '@components/floorPlan/FloorPlan';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { getFullName } from '@utils/formatName';
import {
  ServicePageModalType,
  useServicePageModalContext,
} from 'restaurantAdmin/context/ServicePageModalContext';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { type HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import {
  changeReservationSeat,
  seatReservation,
} from 'restaurantAdmin/reservations/apiHelpers';
import { isReservation, type ServiceReservation } from '../apiHelpers';
import {
  type TableTimerDictionary,
  useReservationServiceContext,
} from '../state/ReservationServiceContext';
import { SeatedFloorPlanTableFactory } from './SeatedFloorPlanTableFactory';

export interface SeatedFloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  highlightedListingId?: string;
  tables: HostFloorPlanTable[];
  tableTimersByFloorPlanTableId: TableTimerDictionary;
  exitSeatMode: () => void;
}

export const ReservationSeatModeFloorPlan = ({
  backgroundSrc,
  className,
  highlightedListingId,
  tableTimersByFloorPlanTableId,
  exitSeatMode,
  tables,
}: SeatedFloorPlanProps) => {
  const { id: restaurantId } = useRestaurant();
  const { closeSidePanelSheet, selectedOccupant } =
    useReservationServiceContext();
  const { openModal } = useServicePageModalContext();

  const createOrUpdateSeat = async (
    floorPlanTableId: string,
    reservationOccupant: ServiceReservation,
  ): Promise<void> => {
    const isSeated = Boolean(reservationOccupant.seatedTableName);
    try {
      if (isSeated) {
        await changeReservationSeat({
          restaurantId,
          reservationId: reservationOccupant.id,
          floorPlanTableId,
        });
      } else {
        await seatReservation({
          restaurantId,
          reservationId: reservationOccupant.id,
          floorPlanTableId,
        });
      }
      closeSidePanelSheet();
      exitSeatMode();
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({ message: error.message });
      } else {
        errorToast({
          message: 'Failed to seat reservation. Please try again.',
        });
      }
      reportAppError(error);
    }
  };

  const onSeatReservationOutsideListing = (
    reservationOccupant: ServiceReservation,
    handleConfirm: () => Promise<void>,
  ): void => {
    const guestName = getFullName(
      reservationOccupant.guest.firstName,
      reservationOccupant.guest.lastName,
    );
    openModal(ServicePageModalType.SeatOutsideListingConfirmation, {
      handleConfirm,
      guestName,
    });
  };

  const isWithinHighlightedListing = (
    floorPlanTable: HostFloorPlanTable,
  ): boolean =>
    !!highlightedListingId &&
    floorPlanTable.listings.some(
      (listing) => listing.id === highlightedListingId,
    );

  useDndMonitor({
    onDragEnd(event: DragEndEvent) {
      const { active, over } = event;

      const floorPlanTable = over?.data.current
        ?.floorPlanTable as HostFloorPlanTable;
      const reservation = active?.data.current
        ?.reservation as ServiceReservation;

      if (
        over &&
        active &&
        over.data.current?.accepts.includes(active.data.current?.type)
      ) {
        const seatReservationOnDragEnd = () =>
          createOrUpdateSeat(floorPlanTable.id, reservation);
        if (isWithinHighlightedListing(floorPlanTable)) {
          void seatReservationOnDragEnd();
        } else {
          onSeatReservationOutsideListing(
            reservation,
            seatReservationOnDragEnd,
          );
        }
      }
    },
  });

  const handleTableOnClick = (floorPlanTable: HostFloorPlanTable) => {
    if (!isReservation(selectedOccupant)) return;

    const seatReservationOnClick = () =>
      createOrUpdateSeat(floorPlanTable.id, selectedOccupant);
    if (isWithinHighlightedListing(floorPlanTable)) {
      void seatReservationOnClick();
    } else {
      onSeatReservationOutsideListing(selectedOccupant, seatReservationOnClick);
    }
  };

  const floorPlanTablesRenderer: FloorPlanTablesRenderer = (tableIconScale) => (
    <>
      {tables.map((floorPlanTable) => (
        <SeatedFloorPlanTableFactory
          floorPlanTable={floorPlanTable}
          calculateIsHighlighted={isWithinHighlightedListing}
          handleTableOnClick={handleTableOnClick}
          key={floorPlanTable.id}
          timer={tableTimersByFloorPlanTableId[floorPlanTable.id]}
          tableIconScale={tableIconScale}
        />
      ))}
    </>
  );

  return (
    <FloorPlan
      backgroundSrc={backgroundSrc}
      className={className}
      floorPlanTablesRenderer={floorPlanTablesRenderer}
    />
  );
};
