import { TZDate } from '@date-fns/tz';
import { createAction } from '@shared/context/reducerUtils';
import type { FloorPlanData } from '@shared/types/floorPlans';

export interface AvailabilityState<
  TAvailability = unknown,
  TSelectedAvailability = TAvailability,
> {
  availabilities: TAvailability[];
  selectedGuestCount: number;
  selectedTime: string | null;
  selectedDate: Date;
  selectedFloorPlanListingIds: string[];
  selectedAvailability: TSelectedAvailability | null;
  floorPlans: FloorPlanData[];
  selectedFloorPlanId: string;
}

export const initialAvailabilityState = <TAvailability, TSelectedAvailability>(
  timezone: string,
): AvailabilityState<TAvailability, TSelectedAvailability> => ({
  availabilities: [],
  selectedGuestCount: 2,
  selectedTime: null,
  selectedDate: TZDate.tz(timezone),
  selectedAvailability: null,
  selectedFloorPlanListingIds: [],
  floorPlans: [],
  selectedFloorPlanId: '',
});

enum AvailabilityActionType {
  SetAvailabilities = 'SetAvailabilities',
  SetSelectedAvailability = 'SetSelectedAvailability',
  SetFloorPlans = 'SetFloorPlans',
  SetSelectedFloorPlanId = 'SetSelectedFloorPlanId',
  ClearSelectedAvailability = 'ClearSelectedAvailability',
  ClearSelectedTime = 'ClearSelectedTime',
  SetSelectedGuestCount = 'SetSelectedGuestCount',
  SetSelectedTime = 'SetSelectedTime',
  SetSelectedDate = 'SetSelectedDate',
  SetSelectedFloorPlanListingIds = 'SetSelectedFloorPlanListingIds',
}

export const setAvailabilities = <TAvailability>(
  availabilities: TAvailability[],
) => createAction(AvailabilityActionType.SetAvailabilities, { availabilities });

export const setSelectedAvailability = <TSelectedAvailability>(
  selectedAvailability: TSelectedAvailability,
) =>
  createAction(AvailabilityActionType.SetSelectedAvailability, {
    selectedAvailability,
  });

export const setFloorPlans = (floorPlans: FloorPlanData[]) =>
  createAction(AvailabilityActionType.SetFloorPlans, { floorPlans });

export const setSelectedFloorPlanId = (selectedFloorPlanId: string) =>
  createAction(AvailabilityActionType.SetSelectedFloorPlanId, {
    selectedFloorPlanId,
  });

export const clearSelectedAvailability = () =>
  createAction(AvailabilityActionType.ClearSelectedAvailability, null);

export const clearSelectedTime = () =>
  createAction(AvailabilityActionType.ClearSelectedTime, null);

export const setSelectedGuestCount = (guestCount: number) =>
  createAction(AvailabilityActionType.SetSelectedGuestCount, {
    guestCount,
  });

export const setSelectedTime = (selectedTime: string) =>
  createAction(AvailabilityActionType.SetSelectedTime, {
    selectedTime,
  });

export const setSelectedDate = (selectedDate: Date) =>
  createAction(AvailabilityActionType.SetSelectedDate, {
    selectedDate,
  });

export const setSelectedFloorPlanListingIds = (
  selectedFloorPlanListingIds: string[],
) =>
  createAction(AvailabilityActionType.SetSelectedFloorPlanListingIds, {
    selectedFloorPlanListingIds,
  });

export const createAvailabilityReducer =
  <TAvailability, TSelectedAvailability>() =>
  (
    state: AvailabilityState<TAvailability, TSelectedAvailability>,
    action:
      | ReturnType<typeof setAvailabilities<TAvailability>>
      | ReturnType<typeof setSelectedAvailability<TSelectedAvailability>>
      | ReturnType<typeof setFloorPlans>
      | ReturnType<typeof setSelectedFloorPlanId>
      | ReturnType<typeof clearSelectedAvailability>
      | ReturnType<typeof clearSelectedTime>
      | ReturnType<typeof setSelectedGuestCount>
      | ReturnType<typeof setSelectedTime>
      | ReturnType<typeof setSelectedDate>
      | ReturnType<typeof setSelectedFloorPlanListingIds>,
  ): AvailabilityState<TAvailability, TSelectedAvailability> => {
    switch (action.type) {
      case AvailabilityActionType.SetAvailabilities:
        return {
          ...state,
          availabilities: action.payload.availabilities,
        };
      case AvailabilityActionType.SetSelectedAvailability:
        return {
          ...state,
          selectedAvailability: action.payload.selectedAvailability,
        };
      case AvailabilityActionType.SetFloorPlans:
        return {
          ...state,
          floorPlans: action.payload.floorPlans,
        };
      case AvailabilityActionType.SetSelectedFloorPlanId:
        return {
          ...state,
          selectedFloorPlanId: action.payload.selectedFloorPlanId,
          selectedAvailability: null,
          selectedFloorPlanListingIds: [],
        };
      case AvailabilityActionType.ClearSelectedAvailability:
        return {
          ...state,
          selectedAvailability: null,
        };
      case AvailabilityActionType.SetSelectedGuestCount:
        return {
          ...state,
          selectedGuestCount: action.payload.guestCount,
          selectedAvailability: null,
        };
      case AvailabilityActionType.SetSelectedTime:
        return {
          ...state,
          selectedTime: action.payload.selectedTime,
          selectedAvailability: null,
        };
      case AvailabilityActionType.ClearSelectedTime:
        return {
          ...state,
          selectedAvailability: null,
          selectedFloorPlanListingIds: [],
          selectedTime: null,
        };
      case AvailabilityActionType.SetSelectedDate:
        return {
          ...state,
          selectedDate: action.payload.selectedDate,
          selectedAvailability: null,
        };
      case AvailabilityActionType.SetSelectedFloorPlanListingIds:
        return {
          ...state,
          selectedFloorPlanListingIds:
            action.payload.selectedFloorPlanListingIds,
        };
      default:
        return state;
    }
  };
