import cx from 'classnames';
import { forwardRef } from 'react';
import FloorPlanTable from '@components/floorPlan/FloorPlanTable';
import type { TableIconCommonProps } from '@components/floorPlan/InteractiveFloorPlanTable';
import { type AssignedServer } from 'restaurantAdmin/floorPlans/apiHelpers';
import typography from '~styles/typography.scss';
import { ServerBadge } from '../sidePanel/servers/ServerBadge';
import { useReservationServiceContext } from '../state/ReservationServiceContext';
import styles from './UnoccupiedFloorPlanTable.scss';
import 'react-tooltip/dist/react-tooltip.css';

export interface FloorPlanTableProps extends TableIconCommonProps {
  assignedServer?: AssignedServer | null;
  timer?: string | null;
  handleTableOnClick: React.MouseEventHandler<HTMLButtonElement>;
  isDisabled?: boolean;
  isHighlighted?: boolean;
  isSelected: boolean;
}

export const UnoccupiedFloorPlanTable = forwardRef<
  HTMLDivElement,
  FloorPlanTableProps
>(
  (
    {
      assignedServer,
      timer,
      handleTableOnClick,
      iconName,
      iconWidthScale,
      isDisabled = false,
      isHighlighted,
      isSelected,
      left,
      name,
      orientation,
      tableIconScale,
      testId,
      top,
    },
    ref,
  ) => {
    const buttonLabel = isHighlighted ? 'Unselect table' : 'Select table';

    const { shouldShowServerBadges } = useReservationServiceContext();

    const pillClassNames = cx({
      [styles.timerPill]: true,
      [typography.t3]: true,
    });

    const timerPillClassNames = cx({
      [pillClassNames]: true,
      [styles.selectedTimerPill]: isSelected,
    });

    const renderPill = () => {
      if (!timer) return null;

      return <span className={timerPillClassNames}>{timer}</span>;
    };

    return (
      <FloorPlanTable
        left={left}
        top={top}
        tableIconScale={tableIconScale}
        data-testid={testId}
        ref={ref}
      >
        <button
          aria-label={buttonLabel}
          className={styles.button}
          data-tooltip-id={name}
          disabled={isDisabled}
          name={name}
          onClick={handleTableOnClick}
          type="button"
        >
          {shouldShowServerBadges && assignedServer && (
            <ServerBadge
              displayMode="floorPlan"
              serverName={assignedServer.name}
              badgeColor={assignedServer.hexColor}
            />
          )}
          <FloorPlanTable.Icon
            iconName={iconName}
            iconWidthScale={iconWidthScale}
            isHighlighted={isHighlighted}
            orientation={orientation}
            tableIconScale={tableIconScale}
          />
          {renderPill()}
        </button>
      </FloorPlanTable>
    );
  },
);

UnoccupiedFloorPlanTable.displayName = 'UnoccupiedFloorPlanTable';
