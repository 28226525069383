import cx from 'classnames';
import type { ReactElement } from 'react';
import { Icon } from '@components/icon/Icon';
import { centsToCompactDollar } from '@utils/currency';
import { appendGuestCountLabel } from '@utils/guestCount';
import { ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import type { AdminAvailabilityData } from './apiHelpers';
import styles from './ConciergeAdminAvailabilityItem.scss';
import { useConciergeAvailabilityContext } from './ConciergeAvailabilityContext';

export interface ConciergeAdminAvailabilityItemProps {
  availability: AdminAvailabilityData;
  availabilityItemAction: ReactElement;
}

export const ConciergeAdminAvailabilityItem = ({
  availability,
  availabilityItemAction,
}: ConciergeAdminAvailabilityItemProps) => {
  const {
    clearSelectedAvailability,
    selectedAvailability,
    selectedGuestCount,
    setSelectedAvailability,
    setSelectedFloorPlanId,
  } = useConciergeAvailabilityContext();
  const {
    listing: { iconName, id: listingId, publicName, isCommunal },
    purchasePrice,
    time,
  } = availability;

  const isSelected =
    selectedAvailability?.listing.id === listingId &&
    selectedAvailability?.time === time;

  const handleAvailabilityClick = () => {
    if (selectedAvailability && isSelected) {
      clearSelectedAvailability();
    } else {
      setSelectedAvailability(availability);
      setSelectedFloorPlanId(availability.listing.floorPlanId);
    }
  };

  const containerStyles = cx(styles.container, {
    [styles.container]: true,
    [styles.isSelected]: isSelected,
  });

  return (
    <li className={containerStyles} key={listingId}>
      <button
        className={styles.availabilityInfo}
        data-testid={`${listingId}-${time}`}
        onClick={handleAvailabilityClick}
      >
        <Icon name={iconName} />
        <div className={styles.column}>
          <div className={styles.row}>
            <span className={cx(typography.c3_20, styles.time)}>
              {ISOTimeTo12HourTime(time)}
            </span>
          </div>
          {isCommunal && (
            <div
              className={cx(typography.t2, styles.description)}
              data-testid="guest-count"
            >
              {appendGuestCountLabel(selectedGuestCount)}
            </div>
          )}
          <div className={cx(typography.t2, styles.description)}>
            {publicName}
          </div>
        </div>
        <div
          className={cx(typography.h7m, styles.price)}
          data-testid="buy-or-make-offer"
        >
          {centsToCompactDollar(purchasePrice)}
        </div>
      </button>
      {isSelected && (
        <div className={styles.ctaContainer}>{availabilityItemAction}</div>
      )}
    </li>
  );
};
