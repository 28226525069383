import { Icon } from '@components/icon/Icon';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { type AssignedServer as AssignedServerType } from 'restaurantAdmin/floorPlans/apiHelpers';
import { type Server } from 'restaurantAdmin/reservations/service/apiHelpers';
import { ServerBadge } from 'restaurantAdmin/reservations/service/sidePanel/servers/ServerBadge';
import typography from '~styles/typography.scss';
import styles from './AssignedServer.scss';
import {
  AssignServerModal,
  type OccupantIdentifier,
} from './AssignServerModal';

export interface AssignedServerProps {
  isEditable?: boolean;
  occupant: OccupantIdentifier;
  server: Server | null;
  submitHandler?: (server: AssignedServerType | null) => void;
}

export const AssignedServer = ({
  isEditable = true,
  occupant,
  server,
  submitHandler,
}: AssignedServerProps) => {
  const { close, isOpen, open } = useIsOpen();

  return (
    <section aria-labelledby="serverHeading" className={styles.container}>
      <header>
        <h3 className={typography.h7m} id="serverHeading">
          Server
        </h3>
        {isEditable && (
          <button className={styles.assignButton} onClick={open} type="button">
            <Icon name="pencil" />
            <span className={typography.t3}>
              {server ? 'Change' : 'Assign'} Server
            </span>
          </button>
        )}
      </header>
      {server ? (
        <div className={styles.serverRow}>
          <ServerBadge
            displayMode="card"
            serverName={server.name}
            badgeColor={server.hexColor}
          />
          <div className={typography.c3_20}>{server.name}</div>
        </div>
      ) : (
        <div className={typography.c3_20}>
          No server is assigned to this table
        </div>
      )}
      {isOpen && (
        <AssignServerModal
          closeModal={close}
          currentServer={server}
          submitHandler={submitHandler}
          isOpen={isOpen}
          occupant={occupant}
        />
      )}
    </section>
  );
};
