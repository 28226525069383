import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormCurrencyInput } from '@components/formInputs/ControlledFormCurrencyInput';
import { ControlledFormDayRadio } from '@components/formInputs/ControlledFormDayRadio';
import { ControlledFormTimeSelect } from '@components/formInputs/ControlledFormTimeSelect';
import { isWholeNumberRule } from '@components/formInputs/rules';
import { LayoutVariant } from '@components/formInputs/sharedTypes';
import { Icon } from '@components/icon/Icon';
import { IconButton } from '@components/iconButton/IconButton';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import typography from '~styles/typography.scss';
import { type PricePointFormData } from '../types';
import {
  findOverlappingPricePointIndexes,
  isOverlappingPricePoints,
} from '../utils/formHelpers';
import styles from './PricePointModal.scss';

export interface PricePointModalProps {
  isOpen: boolean;
  closeModal: () => void;
  pricePointIndex?: number;
  pricePoints: PricePointFormData[];
  handleOnClickRemovePricePoint: () => void;
  handleOnClickOkay: (data: PricePointFormData) => void;
}

export const PricePointModal = ({
  isOpen,
  closeModal,
  pricePointIndex,
  pricePoints,
  handleOnClickRemovePricePoint,
  handleOnClickOkay,
}: PricePointModalProps) => {
  const isEditing = pricePointIndex !== undefined;

  const {
    control,
    handleSubmit,
    watch,
    formState: { isValid },
    getValues,
  } = useForm<PricePointFormData>({
    defaultValues: isEditing
      ? pricePoints[pricePointIndex]
      : { activeDays: [] },
  });
  const [hasOverlap, setHasOverlap] = useState(false);
  const formValues = watch();

  useEffect(() => {
    if (isValid) {
      let isOverlapping;

      if (isEditing) {
        const indexesWithOverlap =
          findOverlappingPricePointIndexes(pricePoints);
        isOverlapping = indexesWithOverlap.includes(pricePointIndex);
      } else {
        const values = getValues();
        isOverlapping = pricePoints.some((pricePointA) =>
          isOverlappingPricePoints(pricePointA, values),
        );
      }

      setHasOverlap(isOverlapping);
    }
  }, [formValues, isValid]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title="Create Price Point"
      subtitle="This price point will be applied on the selected weekdays and time range."
    >
      <form className={styles.form}>
        {isEditing && (
          <IconButton
            ariaLabel="Remove Price Point"
            className={styles.iconButton}
            iconName="trash"
            onClick={handleOnClickRemovePricePoint}
          />
        )}
        <ControlledFormTimeSelect
          control={control}
          label="Start Time"
          name="startTime"
          variant={LayoutVariant.Horizontal}
          rules={{
            required: true,
          }}
        />
        <ControlledFormTimeSelect
          control={control}
          label="End Time"
          name="endTime"
          variant={LayoutVariant.Horizontal}
          rules={{
            required: true,
            validate: {
              isGreaterThanOrEqualToStartTime: (
                endTime: string,
              ): string | boolean =>
                watch('startTime') && endTime >= watch('startTime')
                  ? true
                  : 'End Time must not be earlier than the listing’s Start Time',
            },
          }}
        />
        <ControlledFormCurrencyInput
          control={control}
          label="Price"
          name="price"
          variant={LayoutVariant.Horizontal}
          rules={{
            min: {
              message: 'Price must be greater than or equal to 0',
              value: 0,
            },
            validate: {
              isWholeNumber: isWholeNumberRule('Price'),
            },
            required: true,
          }}
        />
        <ControlledFormDayRadio
          control={control}
          label="Active Days"
          name="activeDays"
          rules={{
            required: true,
          }}
        />
        {hasOverlap && (
          <div className={styles.overlapMessage}>
            <Icon name="alertCircle" />
            <span className={typography.t1}>Overlapping Price Points</span>
            <p className={typography.t1}>
              Some price points in this listing overlap with the other price
              points.
            </p>
          </div>
        )}
      </form>
      <ModalActions>
        <Button
          type="button"
          label="Cancel"
          onClick={closeModal}
          variant={ButtonVariants.Tertiary}
        />
        <Button
          type="button"
          label="Okay"
          onClick={handleSubmit(handleOnClickOkay)}
          variant={ButtonVariants.Primary}
        />
      </ModalActions>
    </Modal>
  );
};
