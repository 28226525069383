/* eslint-disable react/destructuring-assignment */
import { useForm } from 'react-hook-form';
import { Button, ButtonVariants } from '@components/button/Button';
import { ControlledFormSelect } from '@components/formInputs/ControlledFormSelect';
import { Modal } from '@components/modal/Modal';
import { ModalActions } from '@components/modal/ModalActions';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import { type AssignedServer } from 'restaurantAdmin/floorPlans/apiHelpers';
import {
  type OccupantType,
  type Server,
  updateOccupantServer,
} from 'restaurantAdmin/reservations/service/apiHelpers';
import { useServersContext } from 'restaurantAdmin/reservations/service/sidePanel/servers/ServersContext';
import typography from '~styles/typography.scss';
import { ServerBadge } from '../../reservations/service/sidePanel/servers/ServerBadge';
import styles from './AssignServerModal.scss';

export interface AssignServerFormData {
  serverId: string;
}

interface ReservationOccupantData {
  occupantId: string;
  occupantType: OccupantType.Reservation;
}
interface WalkInOccupantData {
  occupantId: string | null; // null for walk-ins that have not yet been saved
  occupantType: OccupantType.WalkIn;
}
export type OccupantIdentifier = ReservationOccupantData | WalkInOccupantData;

export interface AssignServerModalBaseProps {
  closeModal: () => void;
  currentServer: Server | null;
  isOpen: boolean;
  occupant: OccupantIdentifier;
}
export type AssignServerModalPropsWithCustomSubmitHandler =
  AssignServerModalBaseProps & {
    onSuccessfulPersist?: never;
    submitHandler: (server: AssignedServer | null) => void;
  };
export type AssignServerModalPropsForDefaultSubmitHandler =
  AssignServerModalBaseProps & {
    onSuccessfulPersist?: () => void;
    submitHandler?: never;
  };
export type AssignServerModalProps =
  | AssignServerModalBaseProps
  | AssignServerModalPropsWithCustomSubmitHandler
  | AssignServerModalPropsForDefaultSubmitHandler;

interface OptionLabelProps {
  server: Server;
}

export const OptionLabel = ({ server }: OptionLabelProps) => (
  <div className={styles.optionLabel}>
    <ServerBadge
      badgeColor={server.hexColor}
      displayMode="card"
      serverName={server.name}
    />
    <span className={styles.dot}> • </span>
    <span className={typography.c3_20}>{server.name}</span>
  </div>
);

export const getOptions = (
  allServers: Server[],
  currentServer: Server | null,
) => {
  const allButTheCurrentServer = allServers.filter(
    ({ id }) => id !== currentServer?.id,
  );

  const serversAsOptions = allButTheCurrentServer.map((server) => ({
    label: <OptionLabel server={server} />,
    value: server.id,
  }));

  return currentServer
    ? [{ label: 'None', value: '' }, ...serversAsOptions]
    : serversAsOptions;
};

const getSuccessVerb = (currentServer: Server | null, serverId: string) => {
  if (!currentServer) return { past: 'assigned', present: 'assign' };

  return !serverId
    ? { past: 'removed', present: 'remove' }
    : { past: 'updated', present: 'update' };
};

export const AssignServerModal = ({
  closeModal,
  currentServer,
  isOpen,
  occupant: { occupantId, occupantType },
  ...handler
}: AssignServerModalProps) => {
  const { allServers } = useServersContext();
  const { control, handleSubmit } = useForm<AssignServerFormData>();
  const { id: restaurantId } = useRestaurant();

  const options = getOptions(allServers, currentServer);

  const defaultSubmitHandler = async (serverId: string) => {
    if (!occupantId) return;

    const successVerb = getSuccessVerb(currentServer, serverId);

    try {
      await updateOccupantServer({
        occupantId,
        occupantType,
        restaurantId,
        serverId: serverId || null,
      });
      closeModal();
      successToast({
        message: `Server successfully ${successVerb.past}`,
      });
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({
          message: `Failed to ${successVerb.present} server: ${error.message}`,
        });
      } else {
        errorToast({
          message: `Failed to ${successVerb.present} server. Please try again.`,
        });
        reportAppError(error);
      }
    }
  };

  const handleAssignServer = async ({ serverId }: AssignServerFormData) => {
    if ('submitHandler' in handler && handler.submitHandler) {
      const selectedServer = allServers.find(
        (server) => server.id === serverId,
      );
      handler.submitHandler(selectedServer || null);
      closeModal();
    } else {
      await defaultSubmitHandler(serverId);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={closeModal}
      title={`${currentServer ? 'Change' : 'Assign'} Server`}
    >
      <div className={styles.container}>
        {currentServer && (
          <div className={styles.currentServer} data-testid="current-server">
            Current server: <OptionLabel server={currentServer} />
          </div>
        )}
        <form onSubmit={handleSubmit(handleAssignServer)}>
          <ControlledFormSelect
            className={styles.select}
            control={control}
            label="Select a Server"
            name="serverId"
            options={options}
          />
          <ModalActions>
            <Button
              label="Cancel"
              onClick={closeModal}
              variant={ButtonVariants.Tertiary}
            />
            <Button
              label="Save"
              type="submit"
              variant={ButtonVariants.Primary}
            />
          </ModalActions>
        </form>
      </div>
    </Modal>
  );
};
