import './Calendar.overrides.css';
import ReactCalendar from 'react-calendar';
import type { CalendarProps as ReactCalendarProps } from 'react-calendar';
import { Icon } from '@components/icon/Icon';

type CalendarProps = {
  onDateChange: (value: Date) => void;
} & Omit<ReactCalendarProps, 'onChange'> &
  React.RefAttributes<unknown>;

export const Calendar = (props: CalendarProps) => {
  const { onDateChange } = props;
  return (
    <ReactCalendar
      calendarType="gregory"
      maxDetail="month"
      minDetail="month"
      nextAriaLabel="Next Month"
      nextLabel={<Icon name="arrowLeft" className="arrowRight" />}
      next2Label={null}
      onChange={(value) => value instanceof Date && onDateChange(value)}
      prevAriaLabel="Previous Month"
      prevLabel={<Icon name="arrowLeft" className="arrowLeft" />}
      prev2Label={null}
      showNeighboringMonth={false}
      view="month"
      {...props}
    />
  );
};
