import { api } from '../api';

export type ReservationStatus =
  | 'active'
  | 'canceled'
  | 'finished'
  | 'noShow'
  | 'autoResolved'
  | 'resold';

export type WalkInStatus = 'active' | 'finished' | 'autoResolved';

export const isRestaurantClosed = async (
  restaurantId: string,
  date: string,
): Promise<boolean> => {
  // we do not care about the content of the array so not typing it
  const closuresResponse: any[] = await api.get(
    `/restaurants/${restaurantId}/closures?startDate=${date}&endDate=${date}`,
  );

  return !!closuresResponse.length;
};

export const cancel = async (
  restaurantId: string,
  reservationId: string,
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/reservations/${reservationId}/cancel`);

export const collectCancellationFee = async (
  restaurantId: string,
  reservationId: string,
): Promise<void> =>
  api.post(
    `/restaurants/${restaurantId}/reservations/${reservationId}/collect-fee`,
  );

export const waiveCancellationFee = async (
  restaurantId: string,
  reservationId: string,
): Promise<void> =>
  api.post(
    `/restaurants/${restaurantId}/reservations/${reservationId}/waive-fee`,
  );

export const refund = async (
  restaurantId: string,
  reservationId: string,
): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/reservations/${reservationId}/refund`);

export interface UpcomingReservation {
  date: string;
}

export const getUpcomingReservationsByListing = async (
  restaurantId: string,
  listingId: string,
  signal?: AbortSignal,
): Promise<UpcomingReservation[]> =>
  api.get(`/restaurants/${restaurantId}/listings/${listingId}/reservations`, {
    signal,
  });

export const seatReservation = async ({
  restaurantId,
  reservationId,
  floorPlanTableId,
}: {
  restaurantId: string;
  reservationId: string;
  floorPlanTableId: string;
}): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/reservations/seat`, {
    reservationId,
    floorPlanTableId,
  });

export const noShow = async ({
  restaurantId,
  reservationId,
}: {
  restaurantId: string;
  reservationId: string;
}): Promise<void> =>
  api.post(
    `/restaurants/${restaurantId}/reservations/${reservationId}/no-show`,
  );

export const changeReservationSeat = async ({
  restaurantId,
  reservationId,
  floorPlanTableId,
}: {
  restaurantId: string;
  reservationId: string;
  floorPlanTableId: string;
}): Promise<void> =>
  api.put(`/restaurants/${restaurantId}/reservations/seat`, {
    reservationId,
    floorPlanTableId,
  });

export const changeWalkInSeat = async ({
  restaurantId,
  walkInId,
  floorPlanTableId,
  turnTime,
  hasAvailability,
}: {
  restaurantId: string;
  walkInId: string;
  floorPlanTableId: string;
  turnTime: number | null;
  hasAvailability: boolean;
}): Promise<void> =>
  api.put(`/restaurants/${restaurantId}/walk-ins/seat`, {
    walkInId,
    floorPlanTableId,
    turnTime,
    hasAvailability,
  });

export const finishReservation = async ({
  restaurantId,
  reservationId,
}: {
  restaurantId: string;
  reservationId: string;
}): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/reservations/${reservationId}/finish`);

export const unseatReservation = async ({
  restaurantId,
  reservationId,
}: {
  restaurantId: string;
  reservationId: string;
}): Promise<void> =>
  api.delete(`/restaurants/${restaurantId}/reservations/${reservationId}/seat`);

export type OccupantType = 'reservation' | 'walkIn';

export interface GuestAttributes {
  email?: string;
  firstName: string;
  lastName: string;
  phone?: string;
}
export interface ReservationAttributes {
  guest: GuestAttributes;
  guestCount: number;
  guestNotes?: string;
  guestTags: string[];
  hostBookedBy?: string;
  isStranded: boolean;
  listingName: string;
  time: string;
}

export interface RestaurantReservationsReportResponse {
  name: string;
  reservations: ReservationAttributes[];
}

export const seatWalkIn = async (args: {
  firstName?: string;
  floorPlanTableId: string;
  guestCount: number;
  guestId?: string;
  hasAvailability: boolean;
  lastName?: string;
  restaurantId: string;
  saveAsGuest: boolean;
  serverId: string | null;
  turnTime: number | null;
}): Promise<void> => {
  const { restaurantId, ...payload } = args;

  return api.post(`/restaurants/${restaurantId}/walk-ins/seat`, payload);
};

export const seatWaitListEntry = async ({
  floorPlanTableId,
  hasAvailability,
  restaurantId,
  turnTime,
  waitListEntryId,
}: {
  floorPlanTableId: string;
  hasAvailability: boolean;
  restaurantId: string;
  turnTime: number | null;
  waitListEntryId: string;
}): Promise<void> =>
  api.post(
    `/restaurants/${restaurantId}/wait-list-entries/${waitListEntryId}/seat`,
    {
      floorPlanTableId,
      hasAvailability,
      turnTime,
    },
  );

export const discardWalkIn = async ({
  restaurantId,
  walkInId,
}: {
  restaurantId: string;
  walkInId: string;
}): Promise<void> =>
  api.delete(`/restaurants/${restaurantId}/walk-ins/${walkInId}`);

export const finishWalkIn = async ({
  restaurantId,
  walkInId,
}: {
  restaurantId: string;
  walkInId: string;
}): Promise<void> =>
  api.post(`/restaurants/${restaurantId}/walk-ins/${walkInId}/finish`);

interface TimerData {
  timer: string | null;
}

export const getTableTimer = async ({
  floorPlanTableId,
  restaurantId,
}: {
  floorPlanTableId: string;
  restaurantId: string;
}): Promise<TimerData> =>
  api.get(
    `/restaurants/${restaurantId}/floor-plan-tables/${floorPlanTableId}/table-timer`,
  );

export interface WalkInAvailability {
  turnTime: number | null;
  hasAvailability: boolean;
}
export const getWalkInAvailability = async ({
  floorPlanTableId,
  guestCount,
  restaurantId,
}: {
  floorPlanTableId: string;
  guestCount: number;
  restaurantId: string;
}) =>
  api.get<WalkInAvailability>(
    `/restaurants/${restaurantId}/floor-plan-tables/${floorPlanTableId}/walk-in-availability?guestCount=${guestCount}`,
  );

export const updateGuestCount = async ({
  guestCount,
  reservationId,
  restaurantId,
}: {
  guestCount: number;
  reservationId: string;
  restaurantId: string;
}): Promise<void> =>
  api.post(
    `/restaurants/${restaurantId}/reservations/${reservationId}/update-guest-count`,
    {
      guestCount,
    },
  );
