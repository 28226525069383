// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Select__container___yEgjx{position:relative}.Select__container___yEgjx .Select__select___R8zid{align-items:center;color:var(--white);display:flex;height:3rem;padding:1rem;text-align:left;border:0;width:100%;column-gap:16px}.Select__container___yEgjx .Select__select___R8zid svg{margin-left:auto;height:24px;stroke:var(--white);transform:rotate(-90deg)}", "",{"version":3,"sources":["webpack://./src/shared/components/select/Select.scss"],"names":[],"mappings":"AAAA,2BACE,iBAAA,CAEA,mDACE,kBAAA,CACA,kBAAA,CACA,YAAA,CACA,WAAA,CACA,YAAA,CACA,eAAA,CACA,QAAA,CACA,UAAA,CACA,eAAA,CAEA,uDACE,gBAAA,CACA,WAAA,CACA,mBAAA,CACA,wBAAA","sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Select__container___yEgjx",
	"select": "Select__select___R8zid"
};
export default ___CSS_LOADER_EXPORT___;
