import cx from 'classnames';
import { Icon } from '@components/icon/Icon';
import { ISOTimeTo12HourTime } from '@shared/utils/time';
import typography from '~styles/typography.scss';
import styles from './AvailabilityListHeading.scss';

interface AvailabilityListHeadingProps {
  clearSelectedTime: () => void;
  selectedTime: string;
}

export const AvailabilityListHeading = ({
  clearSelectedTime,
  selectedTime,
}: AvailabilityListHeadingProps) => (
  <div className={styles.container}>
    <button
      className={cx(typography.t3, styles.backButton)}
      onClick={clearSelectedTime}
    >
      <Icon name="chevron" />
      All times
    </button>
    <h3 className={cx(typography.c3_20, styles.selectedTime)}>
      {ISOTimeTo12HourTime(selectedTime)}
    </h3>
  </div>
);
