import { Tab, TabPanel, Tabs } from '@mui/base';
import cx from 'classnames';
import { useMemo } from 'react';
import { FloorPlan } from '@components/floorPlan/FloorPlan';
import { floorPlanTablesRendererFactory } from '@components/floorPlan/floorPlanTablesRendererFactory';
import { IconButton } from '@components/iconButton/IconButton';
import { TabsList } from '@components/tabs/TabsList';
import { parseToShortDateRange } from '@shared/utils/dateFormatters';
import { formatWeekDays } from '@shared/utils/weekDayFormatters';
import { centsToWholeDollar } from '@utils/currency';
import { ISOTimeAddMinutes, ISOTimeTo12HourTime } from '@utils/time';
import typography from '~styles/typography.scss';
import type { ListingWithServiceWindows } from '../apiHelpers';
import { useListingsContext } from '../ListingsContext';
import { ListingMenu } from '../menu/ListingMenu';
import {
  hasOverlappingServiceWindow,
  seatingTypeFromIsCommunal,
} from '../utils/listingUtils';
import styles from './ListingDetailsSheet.scss';
import { ListingOverlapWarning } from './ListingOverlapWarning';
import { TableList } from './TableList';

interface ListingDetailsSheetProps {
  filteredListings: ListingWithServiceWindows[];
}

export const ListingDetailsSheet = ({
  filteredListings,
}: ListingDetailsSheetProps) => {
  const { clearSelectedListing, selectedListing, floorPlans } =
    useListingsContext();

  // memoize since this can be expensive
  const hasOverlap = useMemo(
    () =>
      selectedListing &&
      selectedListing.status !== 'draft' &&
      filteredListings.some(
        (listing) =>
          listing.id !== selectedListing.id &&
          hasOverlappingServiceWindow(selectedListing, listing),
      ),
    [filteredListings, selectedListing],
  );

  if (!selectedListing) {
    return null;
  }

  const {
    interval,
    inventoryCount,
    maximumGuests,
    minimumGuests,
    name,
    price,
    publicName,
    turnTime,
    pricePoints,
  } = selectedListing;

  const floorPlan = floorPlans.find(
    (fp) => fp.id === selectedListing?.floorPlanId,
  );
  const highlightedTables =
    floorPlan?.floorPlanTables.filter((t) =>
      selectedListing.highlightedFloorPlanTableIds.includes(t.id),
    ) || [];
  return (
    <article aria-label="Listing Details" className={styles.main}>
      <IconButton
        ariaLabel="close listing details"
        onClick={() => clearSelectedListing()}
        iconName="smallArrowLeft"
        className={styles.closeButton}
      />
      <section className={styles.heading}>
        <div className={styles.names}>
          <h2 className={typography.h5}>{name}</h2>
          <p className={cx(typography.t1, styles.publicName)}>{publicName}</p>
        </div>
        <ListingMenu listing={selectedListing} />
      </section>
      {hasOverlap && <ListingOverlapWarning />}
      <Tabs defaultValue={0}>
        <TabsList>
          <Tab className={typography.t1} value={0}>
            Floor Plan
          </Tab>
          <Tab className={typography.t1} value={1}>
            Service Windows
          </Tab>
          <Tab className={typography.t1} value={2}>
            Pricing
          </Tab>
        </TabsList>
        <TabPanel value={0} className={styles.floorPlanTab}>
          <FloorPlan
            className={styles.floorPlan}
            disableControls
            backgroundSrc={floorPlan?.backgroundSrc}
            floorPlanTablesRenderer={floorPlanTablesRendererFactory({
              calculateIsHighlighted: (floorPlanTable) =>
                selectedListing.highlightedFloorPlanTableIds.includes(
                  floorPlanTable.id,
                ),
              tables: floorPlan?.floorPlanTables || [],
            })}
          />
          <TableList tables={highlightedTables} />
          <section>
            <h3 className={typography.h7}>Listing Details</h3>
            <dl className={typography.t1}>
              <div className={styles.detail}>
                <dt>Guest Facing Name</dt>
                <dd>{publicName}</dd>
              </div>
              <div className={styles.detail}>
                <dt>Seating Type</dt>
                <dd>{seatingTypeFromIsCommunal(selectedListing.isCommunal)}</dd>
              </div>
              <div className={styles.detail}>
                <dt>Min-Max Guests</dt>
                <dd>
                  {minimumGuests}-{maximumGuests}
                </dd>
              </div>
              <div className={styles.detail}>
                <dt>
                  {selectedListing.isCommunal
                    ? 'Number of Seats'
                    : 'Number of Tables'}
                </dt>
                <dd>{inventoryCount}</dd>
              </div>
              <div className={styles.detail}>
                <dt>Turn Time</dt>
                <dd>{turnTime} min</dd>
              </div>
              <div className={styles.detail}>
                <dt>Interval</dt>
                <dd>{interval} min</dd>
              </div>
            </dl>
          </section>
        </TabPanel>
        <TabPanel value={1} className={styles.reservableDaysTab}>
          {selectedListing.serviceWindows.map(
            ({ repeat, startTime, endTime, startDate, endDate, id }) => (
              <div className={styles.card} key={id}>
                <div className={typography.h7m}>{formatWeekDays(repeat)}</div>
                <div
                  className={cx(typography.t1, styles.dateTimeRange)}
                  data-testid="listing-details-time-range"
                >
                  <span>{parseToShortDateRange(startDate, endDate)}</span>
                  <span>
                    {ISOTimeTo12HourTime(startTime)}–
                    {ISOTimeTo12HourTime(endTime)}
                  </span>
                  <span>
                    {` (last turn ends ${ISOTimeTo12HourTime(ISOTimeAddMinutes(endTime, turnTime))})`}
                  </span>
                </div>
              </div>
            ),
          )}
        </TabPanel>
        <TabPanel value={2} className={styles.pricingTab}>
          <div className={styles.basePrice}>
            <span className={typography.h7m}>Base Price</span>
            <span className={typography.h7}>{centsToWholeDollar(price)}</span>
          </div>
          {!!pricePoints.length && (
            <ul>
              {pricePoints.map((p) => (
                <li key={p.id}>
                  <div className={styles.timeAndDayRange}>
                    <span className={typography.h7m}>
                      {ISOTimeTo12HourTime(p.startTime)} –{' '}
                      {ISOTimeTo12HourTime(p.endTime)}
                    </span>
                    <span className={typography.t1}>
                      {formatWeekDays(p.activeDays)}
                    </span>
                  </div>
                  <span className={typography.h7}>
                    {centsToWholeDollar(p.price)}
                  </span>
                </li>
              ))}
            </ul>
          )}
        </TabPanel>
      </Tabs>
    </article>
  );
};
