import {
  FloorPlan,
  type FloorPlanTablesRenderer,
} from '@components/floorPlan/FloorPlan';
import { errorToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { reportAppError } from '@shared/reportAppError';
import {
  ServicePageModalType,
  useServicePageModalContext,
} from 'restaurantAdmin/context/ServicePageModalContext';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import {
  changeWalkInSeat,
  getWalkInAvailability,
  type WalkInAvailability,
} from '../../apiHelpers';
import {
  type TableTimerDictionary,
  useReservationServiceContext,
} from '../state/ReservationServiceContext';
import { SeatedFloorPlanTableFactory } from './SeatedFloorPlanTableFactory';

export interface WalkInSeatModeFloorPlanProps {
  backgroundSrc?: string;
  className?: string;
  exitSeatMode: () => void;
  tables: HostFloorPlanTable[];
  tableTimersByFloorPlanTableId: TableTimerDictionary;
}

interface ChangeWalkInSeatState {
  hostFloorPlanTable: HostFloorPlanTable;
  walkInAvailability: WalkInAvailability;
}

export const WalkInSeatModeFloorPlan = ({
  backgroundSrc,
  className,
  exitSeatMode,
  tables,
  tableTimersByFloorPlanTableId,
}: WalkInSeatModeFloorPlanProps) => {
  const { openModal } = useServicePageModalContext();
  const restaurant = useRestaurant();
  const { closeSidePanelSheet, selectedOccupant } =
    useReservationServiceContext();

  if (!selectedOccupant) return null;

  const changeSeat = async (changeWalkInSeatData: ChangeWalkInSeatState) => {
    if (!changeWalkInSeatData) return;

    try {
      await changeWalkInSeat({
        walkInId: selectedOccupant.id,
        restaurantId: restaurant.id,
        floorPlanTableId: changeWalkInSeatData.hostFloorPlanTable.id,
        turnTime: changeWalkInSeatData.walkInAvailability.turnTime,
        hasAvailability:
          changeWalkInSeatData.walkInAvailability.hasAvailability,
      });

      closeSidePanelSheet();
      exitSeatMode();
    } catch (error) {
      if (error instanceof ApiError) {
        errorToast({ message: error.message });
      } else {
        errorToast({
          message: 'Failed to change walk-in seat. Please try again.',
        });
      }
      reportAppError(error);
    }
  };

  const onChangeWalkInSeating = async (
    hostFloorPlanTable: HostFloorPlanTable,
  ) => {
    try {
      const response = await getWalkInAvailability({
        floorPlanTableId: hostFloorPlanTable.id,
        guestCount: selectedOccupant.guestCount,
        restaurantId: restaurant.id,
      });
      const changeSeatState = {
        hostFloorPlanTable,
        walkInAvailability: response,
      };

      if (!response.hasAvailability && changeSeatState) {
        openModal(ServicePageModalType.WalkInSeatingConflict, {
          handleConfirm: () => {
            void changeSeat(changeSeatState);
          },
        });
        return;
      }

      if (response.turnTime !== null && changeSeatState) {
        openModal(ServicePageModalType.WalkInTurnTimeInformation, {
          handleConfirm: () => {
            void changeSeat(changeSeatState);
          },
          turnTime: changeSeatState.walkInAvailability.turnTime,
        });

        return;
      }

      await changeSeat({
        hostFloorPlanTable,
        walkInAvailability: response,
      });
    } catch (e) {
      errorToast({
        message: 'Failed to confirm seat availability. Please try again.',
      });
      reportAppError(e);
    }
  };

  const floorPlanTablesRenderer: FloorPlanTablesRenderer = (tableIconScale) => (
    <>
      {tables.map((floorPlanTable) => (
        <SeatedFloorPlanTableFactory
          floorPlanTable={floorPlanTable}
          handleTableOnClick={onChangeWalkInSeating}
          key={floorPlanTable.id}
          timer={tableTimersByFloorPlanTableId[floorPlanTable.id] || null}
          tableIconScale={tableIconScale}
        />
      ))}
    </>
  );

  return (
    <FloorPlan
      backgroundSrc={backgroundSrc}
      className={className}
      floorPlanTablesRenderer={floorPlanTablesRenderer}
    />
  );
};
