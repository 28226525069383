import { filter, groupBy, uniq } from 'lodash-es';
import { type AvailabilityState } from '@shared/availability/availabilityReducer';
import { type AdminAvailabilityData } from './apiHelpers';

const filterAvailabilitiesByGuestCount = (
  availabilities: AdminAvailabilityData[],
  guestCount: number,
): AdminAvailabilityData[] =>
  filter(
    availabilities,
    (item: AdminAvailabilityData) => guestCount === item.guestCount,
  );

const filterAvailabilitiesByTime = (
  availabilities: AdminAvailabilityData[],
  selectedTime: string | null,
): AdminAvailabilityData[] => {
  if (!selectedTime) {
    return availabilities;
  }

  return filter(
    availabilities,
    (item: AdminAvailabilityData) => selectedTime === item.time,
  );
};

const filterAvailabilitiesBySelectedFloorPlanListingIds = (
  availabilities: AdminAvailabilityData[],
  selectedFloorPlanListingIds: string[],
): AdminAvailabilityData[] => {
  if (!selectedFloorPlanListingIds.length) {
    return availabilities;
  }

  return filter(availabilities, (item: AdminAvailabilityData) =>
    selectedFloorPlanListingIds.includes(item.listing.id),
  );
};

const timeAndPriceSorter = (
  a: AdminAvailabilityData,
  b: AdminAvailabilityData,
) => {
  const timeComparison = a.time.localeCompare(b.time);
  if (timeComparison !== 0) {
    return timeComparison;
  }
  return a.purchasePrice - b.purchasePrice;
};

export const getAvailabilitiesGroupedByTime = (
  state: AvailabilityState<AdminAvailabilityData>,
): Record<string, AdminAvailabilityData[]> => {
  const filterFns = [
    (availabilities: AdminAvailabilityData[]) =>
      filterAvailabilitiesByGuestCount(
        availabilities,
        state.selectedGuestCount,
      ),
    (availabilities: AdminAvailabilityData[]) =>
      filterAvailabilitiesBySelectedFloorPlanListingIds(
        availabilities,
        state.selectedFloorPlanListingIds,
      ),
  ];

  const filteredSortedAvailabilities = filterFns
    .reduce(
      (filteredData, filterFn) => filterFn(filteredData),
      state.availabilities,
    )
    .sort(timeAndPriceSorter);

  return groupBy(filteredSortedAvailabilities, 'time');
};

// TODO: Refactor this function to be more readable and cohesive/single responsibility
export const calculateFilteredData = (
  state: AvailabilityState<AdminAvailabilityData>,
) => {
  const { availabilities, selectedFloorPlanListingIds } = state;

  const availabilitiesFilteredByGuestCount = filterAvailabilitiesByGuestCount(
    state.availabilities,
    state.selectedGuestCount,
  );

  const availabilitiesFilteredByGuestCountAndTime = filterAvailabilitiesByTime(
    availabilitiesFilteredByGuestCount,
    state.selectedTime,
  );

  const filteredSortedAvailabilities =
    filterAvailabilitiesBySelectedFloorPlanListingIds(
      availabilitiesFilteredByGuestCountAndTime,
      selectedFloorPlanListingIds,
    ).sort(timeAndPriceSorter);

  const hasUnsupportedGuestCount =
    !availabilitiesFilteredByGuestCount.length && !!availabilities.length;

  const expandedAvailabilityListingIds = uniq(
    availabilitiesFilteredByGuestCountAndTime.map(
      (availability) => availability.listing.id,
    ),
  );

  return {
    filteredAvailabilities: filteredSortedAvailabilities,
    expandedAvailabilityListingIds,
    hasUnsupportedGuestCount,
  };
};
