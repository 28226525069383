import { useEffect } from 'react';
import { errorToast } from '@components/toasts/Toasts';

export const useServerSentEvents = (
  restaurantId: string,
  sideEffect?: () => void,
) => {
  useEffect(() => {
    const eventSource = new EventSource(
      `${process.env.API_HOST}/restaurants/${restaurantId}/sse/subscribe`,
      { withCredentials: true },
    );

    eventSource.addEventListener('revalidate_state', () => {
      sideEffect?.();
    });

    eventSource.onerror = () => {
      eventSource.close();
      errorToast({
        message: 'Please refresh your browser.',
      });
    };

    return () => {
      eventSource.close();
    };
  }, [restaurantId]);
};
