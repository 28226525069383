import { useEffect, useState } from 'react';
import { type Control, useFieldArray, useWatch } from 'react-hook-form';
import { Icon } from '@components/icon/Icon';
import { useIsOpen } from '@shared/hooks/useIsOpen';
import { useIsOpenWithData } from '@shared/hooks/useIsOpenWithData';
import { type ListingFormData, type PricePointFormData } from '../types';
import { findOverlappingPricePointIndexes } from '../utils/formHelpers';
import { CopyPricePointsModal } from './CopyPricePointsModal';
import styles from './PricePointFieldSet.scss';
import { PricePointItem } from './PricePointItem';
import { PricePointModal } from './PricePointModal';

interface PricePointsListProps {
  control: Control<ListingFormData>;
}

export const PricePointFieldSet = ({ control }: PricePointsListProps) => {
  const {
    fields,
    append: addPricePoint,
    remove: removePricePoint,
    update: updatePricePoint,
  } = useFieldArray({
    control,
    name: 'pricePoints',
    // this is needed bc the library maintainer decided it was a good idea to use the key name 'id' for this as a default
    keyName: 'key',
  });

  const pricePoints = useWatch({
    control,
    name: 'pricePoints',
  });

  const [overlappingIndexes, setOverlappingIndexes] = useState<number[]>([]);

  const {
    isOpen: isPricePointModalOpen,
    open: openPricePointModal,
    close: closePricePointModal,
    data: pricePointModalData,
  } = useIsOpenWithData<{ pricePointIndex?: number }>();

  const {
    isOpen: isCopyPricePointsModalOpen,
    open: openCopyPricePointsModal,
    close: closeCopyPricePointsModal,
  } = useIsOpen();

  useEffect(() => {
    if (pricePoints?.length && pricePoints.length > 1) {
      const indexesWithOverlap = findOverlappingPricePointIndexes(pricePoints);
      setOverlappingIndexes(indexesWithOverlap);
    }
  }, [pricePoints]);

  return (
    <fieldset className={styles.container}>
      {fields.map(({ key, ...pricePoint }, index) => (
        <button
          key={key}
          className={styles.field}
          type="button"
          onClick={() => openPricePointModal({ pricePointIndex: index })}
          aria-label="Edit Price Point"
          title="Edit Price Point"
        >
          {overlappingIndexes.includes(index) && (
            <Icon
              name="alertCircle"
              className={styles.overlapIcon}
              ariaLabel="overlap indicator"
            />
          )}
          <PricePointItem
            startTime={pricePoint.startTime}
            endTime={pricePoint.endTime}
            price={pricePoint.price}
            activeDays={pricePoint.activeDays}
          />
        </button>
      ))}
      <button
        className={styles.addButton}
        type="button"
        onClick={() => openPricePointModal({ pricePointIndex: undefined })}
      >
        Add Weekly Price Point
        <Icon name="plus" />
      </button>
      <button
        className={styles.addButton}
        type="button"
        onClick={() => openCopyPricePointsModal()}
      >
        Copy Price Points
        <Icon name="plus" />
      </button>
      {isPricePointModalOpen && (
        <PricePointModal
          isOpen
          closeModal={closePricePointModal}
          pricePointIndex={pricePointModalData?.pricePointIndex}
          pricePoints={pricePoints}
          handleOnClickRemovePricePoint={() => {
            removePricePoint(pricePointModalData?.pricePointIndex);
            closePricePointModal();
          }}
          handleOnClickOkay={(data: PricePointFormData) => {
            const isEditing =
              pricePointModalData?.pricePointIndex !== undefined;

            if (isEditing) {
              updatePricePoint(pricePointModalData.pricePointIndex!, data);
            } else {
              addPricePoint(data);
            }

            closePricePointModal();
          }}
        />
      )}
      <CopyPricePointsModal
        isOpen={isCopyPricePointsModalOpen}
        closeModal={closeCopyPricePointsModal}
        addPricePoint={addPricePoint}
      />
    </fieldset>
  );
};
