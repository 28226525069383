import { useDroppable } from '@dnd-kit/core';
import { kebabCase } from 'lodash-es';
import type { HostFloorPlanTable } from 'restaurantAdmin/floorPlans/apiHelpers';
import { useReservationServiceContext } from 'restaurantAdmin/reservations/service/state/ReservationServiceContext';
import { SeatedFloorPlanTable } from './SeatedFloorPlanTable';
import { UnoccupiedFloorPlanTable } from './UnoccupiedFloorPlanTable';

interface SeatedFloorPlanTableFactoryProps {
  timer?: string | null;
  calculateIsHighlighted?: (floorPlanTable: HostFloorPlanTable) => boolean;
  floorPlanTable: HostFloorPlanTable;
  handleTableOnClick: (
    floorPlanTable: HostFloorPlanTable,
  ) => void | Promise<void>;
  tableIconScale: number;
}
export const SeatedFloorPlanTableFactory = ({
  timer = null,
  calculateIsHighlighted = () => false,
  floorPlanTable,
  handleTableOnClick,
  tableIconScale,
}: SeatedFloorPlanTableFactoryProps) => {
  const { seatedOccupant } = floorPlanTable;
  const { selectedOccupant } = useReservationServiceContext();
  const isSeated = seatedOccupant !== null;
  const { setNodeRef } = useDroppable({
    id: floorPlanTable.id,
    data: {
      accepts: !isSeated ? ['reservation', 'waitListEntry'] : [],
      floorPlanTable,
    },
  });
  const testId = kebabCase(`floor-plan-table-${floorPlanTable.name}`);

  if (isSeated) {
    return (
      <SeatedFloorPlanTable
        iconName={floorPlanTable.iconName}
        iconWidthScale={floorPlanTable.iconWidthScale}
        isAnyTableHighlighted={selectedOccupant != null}
        isDisabled
        isHighlighted={selectedOccupant?.id === seatedOccupant.id}
        key={floorPlanTable.id}
        left={floorPlanTable.left}
        name={floorPlanTable.name}
        orientation={floorPlanTable.orientation}
        ref={setNodeRef}
        seatedOccupant={seatedOccupant}
        tableIconScale={tableIconScale}
        tableName={floorPlanTable.name}
        testId={testId}
        top={floorPlanTable.top}
        assignedServer={floorPlanTable.assignedServer}
      />
    );
  }

  return (
    <UnoccupiedFloorPlanTable
      timer={timer}
      handleTableOnClick={() => {
        void handleTableOnClick(floorPlanTable);
      }}
      iconName={floorPlanTable.iconName}
      iconWidthScale={floorPlanTable.iconWidthScale}
      isHighlighted={calculateIsHighlighted(floorPlanTable)}
      isSelected={false}
      key={floorPlanTable.id}
      left={floorPlanTable.left}
      name={floorPlanTable.name}
      orientation={floorPlanTable.orientation}
      ref={setNodeRef}
      tableIconScale={tableIconScale}
      testId={testId}
      top={floorPlanTable.top}
      assignedServer={floorPlanTable.assignedServer}
    />
  );
};
