import {
  createContext,
  type ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useForm, type UseFormReturn } from 'react-hook-form';
import { errorToast, successToast } from '@components/toasts/Toasts';
import { ApiError } from '@shared/api/errors';
import { useDefinedContext } from '@shared/hooks/useDefinedContext';
import { reportAppError } from '@shared/reportAppError';
import { useRestaurant } from 'restaurantAdmin/context/useRestaurant';
import {
  SidePanelSheetMode,
  useReservationServiceContext,
} from '../../state/ReservationServiceContext';
import {
  createServer,
  deleteServer,
  type Server,
  updateServer,
} from './apiHelpers';
import { useServersContext } from './ServersContext';

export const SERVER_BADGE_COLORS = [
  '#1732A2',
  '#C45404',
  '#682689',
  '#157273',
  '#B2426B',
  '#750000',
  '#0E2282',
  '#943102',
  '#4B186E',
  '#083F4D',
  '#761E58',
  '#54000E',
];

export interface ServerFormData {
  id?: string;
  name: string;
  hexColor: string;
  floorPlanTableIds: string[];
}

const DEFAULT_SERVER_FORM_DATA: ServerFormData = {
  name: '',
  hexColor: SERVER_BADGE_COLORS[0],
  floorPlanTableIds: [],
};

export interface ServerFormContextState {
  form: Pick<
    UseFormReturn<ServerFormData>,
    'control' | 'handleSubmit' | 'reset' | 'setValue'
  >;
  handleCreateServer: (
    server: ServerFormData,
    onSuccess: () => void,
  ) => Promise<void>;
  handleDeleteServer: (closeModal: () => void) => void;
  handleUpdateServer: (
    server: ServerFormData,
    onSuccess: () => void,
  ) => Promise<void>;
  selectedServer: Server | null;
  setSelectedServer: (server: Server | null) => void;
}

const ServerFormContext = createContext<ServerFormContextState | null>(null);

export const useServerFormContext = () => useDefinedContext(ServerFormContext);

export const ServerFormContextProvider = ({
  children,
  initialState,
}: {
  children: ReactNode;
  initialState?: Pick<ServerFormContextState, 'selectedServer'>;
}) => {
  const [selectedServer, setSelectedServer] = useState<Server | null>(
    initialState?.selectedServer || null,
  );

  const { id: restaurantId } = useRestaurant();
  const { fetchServers } = useServersContext();
  const { setSidePanelSheet } = useReservationServiceContext();

  const { control, handleSubmit, reset, setValue } = useForm<ServerFormData>({
    defaultValues: DEFAULT_SERVER_FORM_DATA,
  });

  useEffect(() => {
    if (selectedServer) {
      setValue('id', selectedServer.id);
      setValue('name', selectedServer.name);
      setValue('hexColor', selectedServer.hexColor);
      setValue(
        'floorPlanTableIds',
        selectedServer.floorPlanTables.map((table) => table.id),
      );
    }
  }, [selectedServer]);

  const handleCreateServer = async (
    server: ServerFormData,
    onSuccess: () => void,
  ) => {
    try {
      await createServer(restaurantId, server);
      successToast({ message: 'Server successfully created' });
      fetchServers();
      onSuccess();
    } catch (e) {
      if (e instanceof ApiError) {
        errorToast({ message: `Failed to create server: ${e.message}` });
      } else {
        errorToast({ message: 'Failed to create server' });
      }
      reportAppError(e);
    }
  };

  const handleDeleteServer = async (closeModal: () => void) => {
    if (!selectedServer) return;

    try {
      await deleteServer(restaurantId, selectedServer.id);
      closeModal();
      setSelectedServer(null);
      setSidePanelSheet({ mode: SidePanelSheetMode.None });
      successToast({ message: 'Server successfully deleted' });
      fetchServers();
    } catch (e) {
      closeModal();
      if (e instanceof ApiError) {
        errorToast({ message: `Failed to delete server: ${e.message}` });
      } else {
        errorToast({ message: 'Failed to delete server' });
      }
      reportAppError(e);
    }
  };

  const handleUpdateServer = async (
    server: ServerFormData,
    onSuccess: () => void,
  ) => {
    if (!server.id) throw new Error('Server ID is required to update server');

    try {
      await updateServer(restaurantId, server.id, {
        name: server.name,
        hexColor: server.hexColor,
        floorPlanTableIds: server.floorPlanTableIds,
      });
      successToast({ message: 'Server successfully updated' });
      fetchServers();
      onSuccess();
    } catch (e) {
      if (e instanceof ApiError) {
        errorToast({ message: `Failed to update server: ${e.message}` });
      } else {
        errorToast({ message: 'Failed to update server' });
      }
      reportAppError(e);
    }
  };

  const value = useMemo(
    () => ({
      form: {
        control,
        handleSubmit,
        reset,
        setValue,
      },
      handleCreateServer,
      handleDeleteServer,
      handleUpdateServer,
      selectedServer,
      setSelectedServer,
    }),
    [control, restaurantId, selectedServer],
  );

  return (
    <ServerFormContext.Provider value={value}>
      {children}
    </ServerFormContext.Provider>
  );
};
