import cx from 'classnames';
import {
  type Control,
  type UseFormResetField,
  type UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { Card } from '@components/card/Card';
import { ControlledFormSelect } from '@components/formInputs/ControlledFormSelect';
import { type FloorPlanData } from '@shared/types/floorPlans';
import typography from '~styles/typography.scss';
import type { ListingFormData } from '../types';
import { AdvancedOptionsFieldSet } from './AdvancedOptionsFieldSet';
import { BasePriceFieldSet } from './BasePriceFieldSet';
import { FloorPlanFieldset } from './FloorPlanFieldset';
import styles from './ListingForm.scss';
import { PricePointFieldSet } from './PricePointFieldSet';
import { PublicNameFieldSet } from './PublicNameFieldSet';
import { ReservableTimeAndDaysFieldSet } from './ReservableTimeAndDaysFieldSet';

interface ListingFormProps {
  control: Control<ListingFormData>;
  setValue: UseFormSetValue<ListingFormData>;
  resetField: UseFormResetField<ListingFormData>;
  floorPlans: FloorPlanData[];
  isNewListing: boolean;
}

export const ListingForm = ({
  control,
  floorPlans,
  isNewListing,
  setValue,
  resetField,
}: ListingFormProps) => {
  const selectedFloorPlanId = useWatch({
    control,
    name: 'floorPlanId',
  });

  const selectedFloorPlan = floorPlans.find(
    (fp) => fp.id === selectedFloorPlanId,
  )!;

  return (
    <form className={styles.form}>
      <Card className={cx(styles.card, styles.floorPlan)}>
        {floorPlans.length > 1 && (
          <ControlledFormSelect
            control={control}
            label="Select Floor Plan"
            name="floorPlanId"
            options={floorPlans.map((fp) => ({
              label: fp.name,
              value: fp.id,
            }))}
          />
        )}
        <FloorPlanFieldset control={control} floorPlan={selectedFloorPlan} />
      </Card>
      <Card className={styles.card}>
        <h3 className={typography.h7}>Listing Details</h3>
        <PublicNameFieldSet control={control} />
        <ReservableTimeAndDaysFieldSet control={control} setValue={setValue} />
        <AdvancedOptionsFieldSet
          control={control}
          isNewListing={isNewListing}
          setValue={setValue}
          resetField={resetField}
        />
      </Card>
      <Card className={styles.card}>
        <h3 className={typography.h7}>Pricing</h3>
        <BasePriceFieldSet control={control} />
        <PricePointFieldSet control={control} />
      </Card>
    </form>
  );
};
