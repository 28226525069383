import type { ReactElement } from 'react';
import { AvailabilityListHeading } from '@shared/availability/AvailabilityListHeading';
import { groupTimesByServiceWindow } from '@shared/availability/availabilityUtil';
import { AvailableTimes } from '@shared/availability/AvailableTimes';
import styles from './ConciergeAdminAvailability.scss';
import { ConciergeAdminAvailabilityItem } from './ConciergeAdminAvailabilityItem';
import { useConciergeAvailabilityContext } from './ConciergeAvailabilityContext';
import { NoTables } from './NoTables';

interface ConciergeAdminAvailabilityProps {
  availabilityItemAction: ReactElement;
}

export const ConciergeAdminAvailability = ({
  availabilityItemAction,
}: ConciergeAdminAvailabilityProps) => {
  const {
    filteredAvailabilities,
    filteredAvailabilitiesGroupedByTime,
    selectedTime,
    setSelectedTime,
    clearSelectedTime,
  } = useConciergeAvailabilityContext();

  if (!filteredAvailabilities.length) return <NoTables />;

  const availableTimes = Object.keys(filteredAvailabilitiesGroupedByTime);
  const availableTimesGroupedByServiceWindow =
    groupTimesByServiceWindow(availableTimes);

  const serviceWindows = availableTimesGroupedByServiceWindow.map(
    ([serviceWindow, _times]) => serviceWindow,
  );

  return (
    <div data-testid="availability-container" className={styles.container}>
      {selectedTime ? (
        <>
          <AvailabilityListHeading
            clearSelectedTime={clearSelectedTime}
            selectedTime={selectedTime}
          />
          <ul className={styles.list} aria-label="Restaurant Availabilities">
            {filteredAvailabilities.map((a) => (
              <ConciergeAdminAvailabilityItem
                availability={a}
                availabilityItemAction={availabilityItemAction}
                key={a.listing.id + a.time}
              />
            ))}
          </ul>
        </>
      ) : (
        <AvailableTimes
          filteredAvailabilitiesGroupedByTime={
            filteredAvailabilitiesGroupedByTime
          }
          serviceWindows={serviceWindows}
          availableTimesGroupedByServiceWindow={
            availableTimesGroupedByServiceWindow
          }
          onTimeSelect={setSelectedTime}
        />
      )}
    </div>
  );
};
