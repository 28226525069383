import cx from 'classnames';
import { useEffect, useState } from 'react';
import { Icon } from '@components/icon/Icon';
import { getTableTimer } from 'restaurantAdmin/reservations/apiHelpers';
import typography from '~styles/typography.scss';
import { useRestaurant } from '../../../context/useRestaurant';
import styles from './WalkInSheetWarning.scss';

interface WalkInSheetWarningProps {
  floorPlanTableId: string;
}

export const WalkInSheetWarning = ({
  floorPlanTableId,
}: WalkInSheetWarningProps) => {
  const { id: restaurantId } = useRestaurant();
  const [timer, setTimer] = useState<string | null>(null);

  const fetchTimer = async () => {
    const response = await getTableTimer({
      floorPlanTableId,
      restaurantId,
    });
    setTimer(response.timer);
  };

  useEffect(() => {
    void fetchTimer();
  }, [floorPlanTableId, restaurantId]);

  if (timer === null) return null;

  return (
    <div className={cx(typography.t1, styles.upcomingReservationBanner)}>
      <p>
        <Icon className={styles.hourGlass} name="hourglass" /> Upcoming
        Reservation coming in {timer}
      </p>
    </div>
  );
};
